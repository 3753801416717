import {
  WxrpBnbContractAddress,
  WxrpEthereumContractAddress,
  OxpAvaxContractAddress,
  XrpAvaxContractAddress,
  OvxAvaxContractAddress,
  PopsAvaxContractAddress,
  XvrAvaxContractAddress,
  NioAvaxContractAddress,
} from "./constants";
import { BridgeAsset, BridgeChain, IAssetData } from "./types";

const assetsData: IAssetData[] = [
  {
    key: BridgeAsset.eth,
    name: BridgeAsset.eth,
    img: "eth.svg",
  },
  {
    key: BridgeAsset.wxrp,
    name: BridgeAsset.wxrp,
    img: "wxrp.svg",
    transferConfig: {
      [BridgeChain.bnbChain]: WxrpBnbContractAddress,
      [BridgeChain.xrpLedger]: WxrpEthereumContractAddress,
    },
  },
  {
    key: BridgeAsset.xrp,
    name: BridgeAsset.xrp,
    img: "xrp.svg",
    transferConfig: {
      [BridgeChain.avax]: XrpAvaxContractAddress,
    },
    decimals: 6,
    multichain: {
      address: "XRP",
    },
  },
  {
    key: BridgeAsset.oxp,
    name: BridgeAsset.oxp,
    img: "oxp.svg",
    transferConfig: {
      [BridgeChain.avax]: OxpAvaxContractAddress,
    },
    decimals: 6,
    multichain: {
      address: "OXP/rrno7Nj4RkFJLzC4nRaZiLF5aHwcTVon3d",
    },
  },
  {
    key: BridgeAsset.ovx,
    name: BridgeAsset.ovx,
    img: "ovx.svg",
    transferConfig: {
      [BridgeChain.avax]: OvxAvaxContractAddress,
    },
    decimals: 18,
    xrplDecimals: 6,
    multichain: {
      address: "OVX/rDsvn6aJG4YMQdHnuJtP9NLrFp18JYTJUf",
    },
  },
  {
    key: BridgeAsset.pops,
    name: BridgeAsset.pops,
    img: "pops.png",
    transferConfig: {
      [BridgeChain.avax]: PopsAvaxContractAddress,
    },
    decimals: 18,
    xrplDecimals: 6,
    multichain: {
      address: "POP/rDsvn6aJG4YMQdHnuJtP9NLrFp18JYTJUf",
    },
  },
  {
    key: BridgeAsset.xvr,
    name: BridgeAsset.xvr,
    img: "xvr.svg",
    transferConfig: {
      [BridgeChain.avax]: XvrAvaxContractAddress,
    },
    decimals: 18,
    xrplDecimals: 6,
    multichain: {
      address: "XVR/rRCA6gnPkvpKmWwfcYxHQ845gFQ5ThYQe",
    },
  },
  {
    key: BridgeAsset.nio,
    name: BridgeAsset.nio,
    img: "nio.svg",
    transferConfig: {
      [BridgeChain.avax]: NioAvaxContractAddress,
    },
    decimals: 18,
    xrplDecimals: 6,
    multichain: {
      address: "NIO/rawT5z6bFKigVRHqu29TdZ6aBejvMEqxxT",
    },
  },
  {
    key: BridgeAsset.avaxOxp,
    name: "OXP",
    img: "oxp.svg",
    transferConfig: {
      [BridgeChain.avax]: OxpAvaxContractAddress,
    },
    decimals: 6,
    multichain: {
      address: "0xf049b37803de34ba970388149a9add2638025943",
    },
  },
  {
    key: BridgeAsset.avaxXrp,
    name: "XRP",
    img: "xrp.svg",
    transferConfig: {
      [BridgeChain.avax]: XrpAvaxContractAddress,
    },
    decimals: 6,
    multichain: {
      address: "0x90b630991ab2fa3bfcf6e7b380830e1c3fb4bc4a",
    },
  },
  {
    key: BridgeAsset.avaxOvx,
    name: "OVX",
    img: "ovx.svg",
    transferConfig: {
      [BridgeChain.avax]: OvxAvaxContractAddress,
    },
    decimals: 18,
    xrplDecimals: 6,
    multichain: {
      address: "0xb12ca34c83607d6ba0e7e6886d800cfb35045c21",
    },
  },
  {
    key: BridgeAsset.avaxXvr,
    name: "XVR",
    img: "xvr.svg",
    transferConfig: {
      [BridgeChain.avax]: XvrAvaxContractAddress,
    },
    decimals: 6,
    multichain: {
      address: "0x6574e2c013171cd50a6d34e588906047a0d12f2c",
    },
  },
  {
    key: BridgeAsset.avaxNio,
    name: "NIO",
    img: "nio.svg",
    transferConfig: {
      [BridgeChain.avax]: NioAvaxContractAddress,
    },
    decimals: 6,
    multichain: {
      address: "0xd81f558b71a5323e433729009d55159955f8a7f9",
    },
  },
  //TODO:  commented for now
  // {
  //   key: BridgeAsset.avaxPops,
  //   name: "POPS",
  //   img: "pops.png",
  //   transferConfig: {
  //     [BridgeChain.avax]: PopsAvaxContractAddress,
  //   },
  //   decimals: 18,
  // },
];

export default assetsData;
