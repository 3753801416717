import { createTheme, Theme } from "@mui/material/styles";

let theme: Theme = createTheme();

const appTheme = {
    typography: {
        body1: {
            fontSize: "1rem"
        },
        h1: {
            fontSize: "calc(1.375rem + 1.5vw)",
            [theme.breakpoints.up('lg')]: {
                fontSize: '2.5rem',
            },
        },
        h2: {
            fontSize: "calc(1.325rem + .9vw)",
            [theme.breakpoints.up('lg')]: {
                fontSize: '2rem',
            },
        },
        h3: {
            fontSize: "calc(1.3rem + .6vw)",
            [theme.breakpoints.up('lg')]: {
                fontSize: '1.75rem',
            },
        },
        h4: {
            fontSize: "calc(1.275rem + .3vw)",
            [theme.breakpoints.up('lg')]: {
                fontSize: '1.5rem',
            },
        },
        h5: {
            fontSize: "1.25rem",
        },
        h6: {
            fontSize: "1rem",
        },
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    padding: 0,
                }
            }
        },
    },
};

export default appTheme;