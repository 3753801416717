import React from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Button from '@mui/lab/LoadingButton';
import { Theme, useTheme } from "@mui/material/styles";

import SelectChain from "./Select";
import SelectSendAssetValue from "./SelectSendAssetValue";
import SelectReceiveAssetValue from "./SelectReceiveAssetValue";
import SelectChainFromValue from "./SelectChainFromValue";
import SelectChainToValue from "./SelectChainToValue";
import SelectChainOptions from "./SelectChainOptions";
import SelectAssetsOptions from "./SelectAssetsOptions";
import SelectWalletOptions from "./SelectWalletOptions";
import ConnectWallets from "./ConnectWallets";
import Steps from "./Steps";
import { ReactComponent as SwitchIcon } from "../assets/images/switch.svg";
import { ReactComponent as SwitchDarkIcon } from "../assets/images/switchDark.svg";
import { ReactComponent as MultichainIcon } from "../assets/images/multichainLight.svg";
import { ReactComponent as MultichainDarkIcon } from "../assets/images/multichainDark.svg";
import { useAppState } from "../stores";
import { IChainData, IWallet, IAssetData } from "../assets/types";

const formWrapper = (theme: Theme) => ({
  padding: { xs: "24px", sm: "24px 48px" },
  marginBottom: "24px",
  fontFamily: `"Kallisto", "Helvetica", "Arial", sans-serif`,
  fontWeight: "bold",
  background: "transparent",
  color: theme.palette.button.text,
  border: "1px solid",
  borderColor: "border.main",
  borderRadius: "24px",
});

// const finishButton = (theme: any) => ({
//   background: "#aa3b1a",
//   boxShadow: "none",
//   padding: "5px 15px",
//   fontSize: { xs: "0.625rem", sm: "0.875rem" },
//   fontWeight: 400,
//   lineHeight: 1.2,
//   textTransform: "capitalize",
//   color: theme.palette.text.button,
//   "&:hover": {
//     background: "#aa3b1a",
//     opacity: 0.9,
//   },
// });

const arrowRight = (theme: Theme) => ({
  display: "block",
  width: { xs: 12, sm: 15 },
  height: { xs: 12, sm: 15 },
  margin: "0 auto",
  border: "1px solid",
  borderColor: theme.palette.text.primary,
  borderBottom: "none",
  borderLeft: "none",
  transform: "rotate(45deg)",
});

const switchSx = {
  svg: {
    width: { xs: "35px", sm: "43px" },
    height: { xs: "35px", sm: "43px" },
  },
};

interface ChooseChainsStepProps { }

const ChooseChainsStep: React.FC<ChooseChainsStepProps> = observer(() => {
  const {
    bridge: {
      setChainFrom,
      setChainTo,
      switchChains,
      setChainFromWallet,
      setSendAsset,
      isBridgeMode,
      activeStep,
      canAddTokenToWallet,
      addTokenToWallet,
      isWalletConnected,
      approveTokensToSpend,
      setTrustLine,
      setActiveStepAndBalanceAsync,
      steps,
      errors: { assetSendError },
    },
  } = useAppState();

  const theme = useTheme();

  const onSelectFrom = (chain: IChainData) => {
    setChainFrom(chain);
  };

  const onSelectTo = (chain: IChainData) => {
    setChainTo(chain);
  };

  const onSelectAssets = (asset: IAssetData) => {
    setSendAsset(asset);
  };

  const onSelectWallet = (wallet: IWallet) => {
    setChainFromWallet(wallet);
  };

  if (activeStep > 0) {
    return <ChooseChainsStepFinished />;
  }

  return (
    <>
      {isBridgeMode && (
        <Box sx={formWrapper}>
          <Grid container rowSpacing={3} mt={0}>
            {/* <Grid container mb={2}>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <Box>
                  <Button variant="text" sx={finishButton} onClick={() => setBridgeMode(false)}>
                    Finish Transfer
                  </Button>
                </Box>
              </Grid>
            </Grid> */}
            <Grid container justifyContent="center" mb={4}>
              <Grid item xs={8}>
                <Steps />
              </Grid>
            </Grid>
            <Grid container mb={3}>
              <Typography
                variant="h4"
                align="left"
                sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
              >
                Choose Blockchain and Asset
              </Typography>
            </Grid>
            <Grid container mb={2}>
              <Typography align="left" mb={1}>
                From
              </Typography>
              <SelectChain value={<SelectChainFromValue />}>
                <SelectChainOptions handleSelect={onSelectFrom} />
              </SelectChain>
            </Grid>
            <Grid container justifyContent="center" sx={switchSx}>
              {theme.palette.mode !== "dark" ? (
                <SwitchIcon cursor="pointer" onClick={switchChains} />
              ) : (
                <SwitchDarkIcon cursor="pointer" onClick={switchChains} />
              )}
            </Grid>
            <Grid container mb={3} mt={-1}>
              <Typography align="left" mb={1}>
                To
              </Typography>
              <Grid item xs={12}>
                <SelectChain value={<SelectChainToValue />}>
                  <SelectChainOptions handleSelect={onSelectTo} />
                </SelectChain>
              </Grid>
            </Grid>
            <Grid container mb={3}>
              <Grid item xs={12} container mb={1}>
                <Grid item xs>
                  <Typography align="left">Choose Assets</Typography>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Typography align="left">Receive</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container alignItems="center">
                <Grid item xs={7} sm={9}>
                  <SelectChain value={<SelectSendAssetValue />}>
                    <SelectAssetsOptions handleSelect={onSelectAssets} />
                  </SelectChain>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  alignItems="center"
                  pr={{ xs: "5px", sm: "10px" }}
                >
                  <Box component="span" sx={arrowRight}></Box>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <SelectReceiveAssetValue />
                </Grid>
                <Grid item xs={12}>
                  {assetSendError && (
                    <Box mt={"4px"} pl={"20px"} textAlign="left">
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          fontWeight: 700,
                          lineHeight: "1rem",
                          color: "text.error",
                        }}
                      >
                        {assetSendError}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2} justifyContent="space-between">
              <Grid item xs={6}>
                <Button
                  color="secondary"
                  variant="onxrpPrimary"
                  disabled={!canAddTokenToWallet()}
                  fullWidth
                  onClick={() => addTokenToWallet()}
                >
                  Add token{" "}
                  <Box
                    component={"span"}
                    sx={{ display: { xs: "none", sm: "inline-flex" }, marginLeft: "2px" }}
                  >
                    to wallet
                  </Box>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="secondary"
                  variant="onxrpPrimary"
                  disabled={!isWalletConnected}
                  fullWidth
                  onClick={() => setTrustLine()}
                >
                  Set trustline
                </Button>
              </Grid>
            </Grid>
            <Grid container mb={2}>
              {isWalletConnected ? (
                <Button
                  color="secondary"
                  variant="onxrpPrimary"
                  disabled={!isWalletConnected}
                  fullWidth
                  onClick={() => setActiveStepAndBalanceAsync(steps.address)}
                >
                  Continue
                </Button>
              ) : (
                <ConnectWallets fullWidth={true} variant="onxrpPrimary">
                  <SelectWalletOptions handleSelect={onSelectWallet} />
                </ConnectWallets>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"} mt={2}>
            <PoweredBy />
          </Grid>
        </Box>
      )}
    </>
  );
});

const ChooseChainsStepFinished: React.FC<any> = observer(() => {
  const {
    bridge: { chainFrom, chainTo, assetSend, activeStep, steps, setActiveStep },
  } = useAppState();

  return (
    <>
      <Box sx={formWrapper}>
        <Grid container rowSpacing={3} mt={0}>
          <Grid
            container
            mb={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid>
              <Typography
                variant="h4"
                align="left"
                sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
              >
                Choose Blockchain and Asset
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={"auto"}
              justifyContent="end"
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              {activeStep === steps.address && (
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setActiveStep(steps.chains);
                  }}
                >
                  <Typography
                    align="right"
                    mr={1}
                    fontWeight="400"
                    sx={{
                      padding: "0px 16px",
                      textAlign: "center",
                      borderRadius: 24,
                      border: "1px solid",
                      margin: 0,
                    }}
                  >
                    Edit
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={12} sm={4}>
              <Typography align="left" mr={1}>
                From:
              </Typography>
              <Typography align="left" fontWeight="400">
                {chainFrom?.name}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={4}>
              <Typography align="left" mr={1}>
                To:
              </Typography>
              <Typography align="left" fontWeight="400">
                {chainTo?.name}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={4}>
              <Typography align="left" mr={1}>
                Asset:
              </Typography>
              <Typography align="left" fontWeight="400">
                {assetSend?.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ display: { xs: "flex", sm: "none" } }}>
            <Grid item xs={12}>
              {activeStep === steps.address && (
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "end",
                  }}
                  onClick={() => {
                    setActiveStep(steps.chains);
                  }}
                >
                  <Typography
                    align="right"
                    mr={1}
                    fontWeight="400"
                    sx={{
                      padding: "0px 16px",
                      textAlign: "center",
                      borderRadius: 24,
                      fontSize: "0.6255rem",
                      border: "1px solid",
                      margin: 0,
                    }}
                  >
                    Edit
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} mt={3}>
          <PoweredBy />
        </Grid>
      </Box>
    </>
  );
});

const PoweredBy: React.FC<any> = () => {
  const theme = useTheme();

  return (
    <>
      <Link href="https://multichain.org/" target="_blank" sx={{ display: "flex", }}>
        <Typography pr={1} fontSize="0.75rem" lineHeight={1} mt={"3px"}>
          Powered by
        </Typography>
        {theme.palette.mode !== "dark"
          ? <MultichainIcon width="100px" height="auto" />
          : <MultichainDarkIcon width="100px" height="auto" />
        }
      </Link>
    </>
  )
}

export default ChooseChainsStep;
