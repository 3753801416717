import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { IChainData } from "../assets/types";

interface SelectChainOptionProps {
  chain: IChainData;
}

const SelectChainOption: React.FC<SelectChainOptionProps> = ({ chain }) => {

  return (
    <>
      <Grid container alignContent="center">
        <Grid
          item
          mr={2}
          sx={{
            "& img": {
              display: "flex",
              maxWidth: "25px",
              maxHeight: "25px",
              width: "100%",
              height: "100%",
            },
          }}
        >
          {chain?.img ? (
            <img
              src={"/assets/images/chain-icons/" + chain.img}
              alt={chain.name}
            />
          ) : (
            <img
              src={"/assets/images/icons/unknown-icon.svg"}
              alt={chain.name}
            />
          )}
        </Grid>
        <Grid item>
          <Typography>
            {chain.name}
          </Typography>
        </Grid>

      </Grid>
    </>
  );
};

export default SelectChainOption;
