import { BridgeAsset, BridgeChain, BridgeWallet, IChainData } from "./types";

export const defaultChains: BridgeChain[] = [BridgeChain.xrpLedger, BridgeChain.avax];

const chainsData: IChainData[] = [
  {
    key: BridgeChain.ethereum,
    name: "Ethereum",
    img: "ethereum.svg",
    wallets: [BridgeWallet.metaMask, BridgeWallet.walletConnect],
    assets: {
      [BridgeChain.xrpLedger]: {
        allowedSendAssets: {
          [BridgeAsset.wxrp]: {
            allowedReceiveAsset: {
              [BridgeAsset.xrp]: {
                receivedAssetKey: BridgeAsset.xrp,
              },
            },
            isDisable: false,
          },
        },
      },
    },
    active: true,
    hide: true,
    chainData: {
      id: "0x1",
    },
    blockchainId: "ETH",
    txScanBaseUrl: "https://etherscan.io/tx/",
  },
  {
    key: BridgeChain.xrpLedger,
    name: "XRP",
    img: "xrpl.svg",
    wallets: [BridgeWallet.xumm],
    assets: {
      [BridgeChain.avax]: {
        allowedSendAssets: {
          [BridgeAsset.oxp]: {
            allowedReceiveAsset: {
              [BridgeAsset.avaxOxp]: {
                receivedAssetKey: BridgeAsset.avaxOxp,
              },
            },
          },
          [BridgeAsset.xrp]: {
            allowedReceiveAsset: {
              [BridgeAsset.avaxXrp]: {
                receivedAssetKey: BridgeAsset.avaxXrp,
              },
            },
            isDisable: false,
          },
          [BridgeAsset.ovx]: {
            allowedReceiveAsset: {
              [BridgeAsset.avaxOvx]: {
                receivedAssetKey: BridgeAsset.avaxOvx,
              },
            },
            isDisable: false,
          },
          [BridgeAsset.pops]: {
            allowedReceiveAsset: {
              [BridgeAsset.avaxPops]: {
                receivedAssetKey: BridgeAsset.avaxPops,
              },
            },
            isDisable: true,
          },
          [BridgeAsset.xvr]: {
            allowedReceiveAsset: {
              [BridgeAsset.avaxXvr]: {
                receivedAssetKey: BridgeAsset.avaxXvr,
              },
            },
          },
          [BridgeAsset.nio]: {
            allowedReceiveAsset: {
              [BridgeAsset.avaxNio]: {
                receivedAssetKey: BridgeAsset.avaxNio,
              },
            },
          },
        },
      },
    },
    active: true,
    blockchainId: "XRPL",
    txScanBaseUrl: "https://xrpscan.com/tx/",
    multichain: {
      id: "XRP",
    },
  },
  {
    key: BridgeChain.bnbChain,
    name: "BNB Chain",
    img: "binance.svg",
    wallets: [BridgeWallet.metaMask, BridgeWallet.walletConnect],
    assets: {
      [BridgeChain.ethereum]: {
        allowedSendAssets: {
          [BridgeAsset.wxrp]: {
            allowedReceiveAsset: {
              [BridgeAsset.wxrp]: {
                receivedAssetKey: BridgeAsset.wxrp,
              },
            },
          },
        },
      },
      [BridgeChain.xrpLedger]: {
        allowedSendAssets: {
          [BridgeAsset.wxrp]: {
            allowedReceiveAsset: {
              [BridgeAsset.xrp]: {
                receivedAssetKey: BridgeAsset.xrp,
              },
            },
          },
        },
      },
      [BridgeChain.avax]: {
        allowedSendAssets: {
          [BridgeAsset.eth]: {
            allowedReceiveAsset: {
              [BridgeAsset.avaxEth]: {
                receivedAssetKey: BridgeAsset.avaxEth,
              },
            },
          },
        },
      },
    },
    active: true,
    hide: true,
    chainData: {
      id: "0x38",
      chainParams: {
        chainId: "0x38",
        chainName: "Binance Smart Chain Mainnet",
        rpcUrls: ["https://bsc-dataseed1.defibit.io/"],
      },
    },
    blockchainId: "BSC",
    txScanBaseUrl: "https://bscscan.com/tx/",
  },
  {
    key: BridgeChain.avax,
    name: "Avalanche",
    img: "avax.svg",
    wallets: [BridgeWallet.metaMask, BridgeWallet.walletConnect],
    assets: {
      [BridgeChain.bnbChain]: {
        allowedSendAssets: {
          [BridgeAsset.avaxEth]: {
            allowedReceiveAsset: {
              [BridgeAsset.eth]: {
                receivedAssetKey: BridgeAsset.eth,
              },
            },
          },
        },
      },
      [BridgeChain.xrpLedger]: {
        allowedSendAssets: {
          [BridgeAsset.avaxOxp]: {
            allowedReceiveAsset: {
              [BridgeAsset.oxp]: {
                receivedAssetKey: BridgeAsset.oxp,
              },
            },
          },
          [BridgeAsset.avaxXrp]: {
            allowedReceiveAsset: {
              [BridgeAsset.xrp]: {
                receivedAssetKey: BridgeAsset.xrp,
              },
            },
          },
          [BridgeAsset.avaxOvx]: {
            allowedReceiveAsset: {
              [BridgeAsset.ovx]: {
                receivedAssetKey: BridgeAsset.ovx,
              },
            },
          },
          [BridgeAsset.avaxPops]: {
            allowedReceiveAsset: {
              [BridgeAsset.pops]: {
                receivedAssetKey: BridgeAsset.pops,
              },
            },
          },
          [BridgeAsset.avaxXvr]: {
            allowedReceiveAsset: {
              [BridgeAsset.xvr]: {
                receivedAssetKey: BridgeAsset.xvr,
              },
            },
          },
          [BridgeAsset.avaxNio]: {
            allowedReceiveAsset: {
              [BridgeAsset.nio]: {
                receivedAssetKey: BridgeAsset.nio,
              },
            },
          },
        },
      },
    },
    active: true,
    hide: false,
    chainData: {
      id: "0xA86A",
      chainParams: {
        chainId: "0xA86A",
        chainName: "Avalanche C-Chain",
        rpcUrls: ["https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc"],
      },
    },
    blockchainId: "AVX",
    txScanBaseUrl: "https://snowtrace.io/tx/",
    multichain: {
      id: 43114,
    },
  },
];

export default chainsData;
