import axios from "axios";
import { utils } from "ethers";

import {
    allBridgeBalanceEndpoint,
    allBridgeSignEndpoint,
    allBridgeUnlockEndpoint,
    formatAddressTo32Hex,
    LockFundsResult,
    SupportedTokenSource,
    TransactionStatusChangedCallback
} from "./utils/wallets";
import { XummWallet } from "./utils/xummWallet";

const contractAddress = process.env.REACT_APP_XRP_CONTRACT_ADDRESS;

export class AllbridgeXrplXumm extends XummWallet {
    async lockFunds(
        recipient: string,
        destination: SupportedTokenSource,
        amount: number,
        userToken?: string,
        onTransactionStatusChanged?: TransactionStatusChangedCallback): Promise<LockFundsResult> {
        try {
            const destinationBytes = utils.hexlify(utils.toUtf8Bytes(destination)) + "00";
            const formattedRecepient = formatAddressTo32Hex(recipient);

            const transaction: any = {
                "txjson": {
                    "TransactionType": "Payment",
                    "SourceTag": 69420589,
                    "Destination": contractAddress,
                    "DestinationTag": destinationBytes,
                    "InvoiceID": formattedRecepient,
                    "Amount": (amount * 1000000).toString(),
                }
            };
            if (this.usePushNotifications && userToken != null) {
                transaction["user_token"] = userToken;
            }
            const response = await this.postTransactionAndSubscribe(transaction, () => true);
            const { payload: { response: { txid } } } = response;

            if (onTransactionStatusChanged != null) {
                onTransactionStatusChanged(txid);
            }

            await new Promise(resolve => {
                setTimeout(resolve, 30000);
            }); // Wait for 5 confirmations. TODO: find more stable way

            const { data: {
                lockId: innerLockId,
                recipient: apiRecipient,
                amount: apiAmount,
                source,
                tokenSource,
                tokenSourceAddress,
                signature,
            } } = await axios.get(allBridgeSignEndpoint + txid);

            return {
                lockId: innerLockId as string,
                recipient: apiRecipient as string,
                amount: apiAmount as string,
                source: source as SupportedTokenSource,
                tokenSource: tokenSource as SupportedTokenSource,
                tokenSourceAddress: tokenSourceAddress as string,
                signature: signature as string,
            };
        }
        catch (err) {
            throw err;
        }
    }

    async unlockFunds(
        lockId: string,
        recipient: string,
        amount: string,
        lockSource: SupportedTokenSource,
        tokenSource: SupportedTokenSource,
        tokenSourceAddress: string,
        signature: string) {
        try {
            const result = await axios.post(allBridgeUnlockEndpoint, {
                lockId: lockId,
                recipient: recipient,
                amount: amount,
                source: lockSource,
                tokenSource: tokenSource,
                tokenSourceAddress: tokenSourceAddress,
                signature: signature,
            });
            return result.status;
        }
        catch (err) {
            throw err;
        }
    }

    async getBalance(userAddress: string) {
        try {
            const result = await axios.get(allBridgeBalanceEndpoint + userAddress);
            const { balance } = result.data;
            return (+balance / 1000000).toString();
        }
        catch (err) {
            throw err;
        }
    }
}