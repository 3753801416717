import React from "react";
import { observer } from "mobx-react";

import { useAppState } from "../stores";
import SelectAssetsOption from "./SelectAssetsOption";

interface SelectSendAssetValueProps {
}

const SelectSendAssetValue: React.FC<SelectSendAssetValueProps> = observer(() => {
  const { bridge: { assetSend } } = useAppState();

  return (
    <>
      {assetSend &&
        <SelectAssetsOption asset={assetSend} />
      }
    </>
  );
});

export default SelectSendAssetValue;
