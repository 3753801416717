import { useContext, createContext } from "react";

import States from "./states";
import MetaMaskWalletStore from "./metaMaskWalletStore";
import XummWalletStore from "./xummWalletStore";
import BridgePageStore from "./bridgePageStore";

class RootStore {
    public states: States;
    public metaMask: MetaMaskWalletStore;
    public xumm: XummWalletStore;
    public bridge: BridgePageStore;

    constructor() {
        this.states = new States(this);
        this.metaMask = new MetaMaskWalletStore(this);
        this.xumm = new XummWalletStore(this);
        this.bridge = new BridgePageStore(this);
    }
}

export const RootStoreContext = createContext<RootStore>({} as RootStore);
export const useAppState = () => useContext(RootStoreContext);
export default RootStore;