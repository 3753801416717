import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { isValidClassicAddress, isValidXAddress } from "ripple-address-codec";
import { utils } from "ethers";

import assetsData from "@/assets/assetsData";
import walletsData from "@/assets/walletsData";
import chainsData, { defaultChains } from "@/assets/chainsData";
import {
  BridgeChain,
  BridgeWallet,
  IAssetData,
  IChainData,
  IWallet,
  txOptionsType,
} from "@/assets/types";

import RootStore from "./index";
import {
  allBridgeSignEndpoint,
  getTokenAddress,
  isEthWallet,
  isXrpWallet,
  LockFundsResult,
  anySwapAPI,
} from "./bridgeHelpers/utils/wallets";
import {
  avaxToBnbFlow,
  avaxToXrpFlow,
  FlowDirection,
  isSendOrReceiveFlow,
  xrpToBnbFlow,
} from "./bridgeHelpers/utils/flows";

export default class BridgePageStore {
  public rootStore: RootStore;
  private chainFromWallet?: IWallet;
  private chainToWallet?: IWallet;
  public steps: { [key: string]: number } = {
    chains: 0,
    address: 1,
    confirm: 2,
  };
  // tmp solution
  public savedFinishedChain = "";
  public activeStep: number = 0;
  public chainFrom?: IChainData;
  public chainTo?: IChainData;
  public assetSend?: IAssetData;
  public assetReceive?: IAssetData;
  public addressToInputValue?: string;
  public amountToInputValue?: string;
  public isSendTxConfirmed: boolean = false;
  public transactionProgress: number = 0;
  public showConfirmations: boolean = false;

  public transferToInputValue?: string;
  public isTransferSubmitting: boolean = false;
  public isTransferCompleted: boolean = false;
  public isBridgeMode: boolean = true;
  public isConnectToWalletAllowed?: boolean = false;
  public isWalletConnected?: boolean = false;

  public isReceiveSubmitting: boolean = false;
  public isReceiveCompleted: boolean = false;
  public isWaitingWallet: boolean = false;
  public sourceTransactionId?: string;
  public destTransactionId?: string;
  public isSendAllowed?: boolean = false;
  public balance?: string;
  public errors: {
    chainFromError?: string;
    chainToError?: string;
    assetSendError?: string;
    addressToInputError?: string;
    amountToInputError?: string;
    transferToInputError?: string;
  } = {};
  public isAddressHidden: boolean = false;
  public isApproveButtonLoading: boolean = false;
  public txOptions?: txOptionsType;

  private lockFundsResult?: LockFundsResult;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    this.init();
  }

  init() {
    const chainFromKey = defaultChains[0];
    const chainToKey = defaultChains[1];
    const chainFrom = chainsData.find((chain) => chain.key === chainFromKey);
    const chainTo = chainsData.find((chain) => chain.key === chainToKey);

    if (chainFrom) this.setChainFrom(chainFrom);
    if (chainTo) this.setChainTo(chainTo);
    if (chainFrom && chainTo) this.setDefaultAssets();
  }

  async connectWallet() {
    if (!this.assetSend) {
      this.errors.assetSendError = "Asset is not selected.";
      return;
    }

    if (this.chainFrom == null || this.chainTo == null) {
      this.errors.assetSendError = "Chains are not selected.";
      return;
    }

    if (this.chainFromWallet == null) {
      this.errors.assetSendError = "Wallets are not selected.";
      return;
    }

    this.isWaitingWallet = true;

    if (isSendOrReceiveFlow(avaxToBnbFlow, this.chainFrom, this.chainTo)) {
      this.isAddressHidden = true;
      this.rootStore.metaMask.toggleMultichain(true);
      this.isSendAllowed = true;
    } else {
      this.isAddressHidden = false;
      this.rootStore.metaMask.toggleMultichain(false);
      this.isSendAllowed = true;
    }

    if (isSendOrReceiveFlow(avaxToXrpFlow, this.chainFrom, this.chainTo)) {
      this.rootStore.metaMask.toggleMultichain(true);
      this.rootStore.xumm.toggleMultichain(true);
    } else {
      this.rootStore.metaMask.toggleMultichain(false);
      this.rootStore.xumm.toggleMultichain(false);
    }

    if (isEthWallet(this.chainFromWallet.key)) {
      if (this.chainFrom?.chainData) {
        await this.rootStore.metaMask.init(
          this.chainFrom.chainData,
          this.chainFromWallet?.key === BridgeWallet.walletConnect
        );
      }
      await this.rootStore.metaMask.signIn();

      const tokenAddress = getTokenAddress(this.assetSend, this.chainFrom);
      const isSendAllowed = await this.rootStore.metaMask.isAllowed(
        tokenAddress
      );

      runInAction(() => {
        this.isSendAllowed = isSendAllowed;
      });

      const { formattedBalance } = await this.rootStore.metaMask.getBalance(
        tokenAddress,
        this.assetSend.decimals
      );

      runInAction(() => {
        this.balance = formattedBalance || "0";
      });

      this.isWaitingWallet = false;
    }

    if (isXrpWallet(this.chainFromWallet.key)) {
      await this.rootStore.xumm.signIn(() => {
        this.isWaitingWallet = false;
      });

      runInAction(() => {
        this.isSendAllowed = true;
      });

      const xummBalance = await this.rootStore.xumm.getBalance(
        this.assetSend.key
      );

      runInAction(() => {
        this.balance = xummBalance || "0";
      });
    }

    runInAction(() => {
      this.isWalletConnected = true;
    });
  }

  async disconnectWallet() {
    if (this.chainFromWallet == null) {
      // this.errors.assetSendError = "Wallets are not selected.";
      return;
    }

    if (isEthWallet(this.chainFromWallet.key)) {
      await this.rootStore.metaMask.disconnect();
      runInAction(() => {
        this.isWalletConnected = false;
        this.bridgeAgain();
      });
    }

    if (isXrpWallet(this.chainFromWallet.key)) {
      await this.rootStore.xumm.disconnect();
      runInAction(() => {
        this.isWalletConnected = false;
        this.bridgeAgain();
      });
    }
  }

  async updateBalances() {
    let defaultBalance = "0";
    if (!this.chainFromWallet || !this.assetSend || !this.chainFrom)
      return (this.balance = defaultBalance);

    try {
      if (isEthWallet(this.chainFromWallet.key)) {
        const tokenAddress = getTokenAddress(this.assetSend, this.chainFrom);
        const isSendAllowed = await this.rootStore.metaMask.isAllowed(
          tokenAddress
        );
        const { formattedBalance } = await this.rootStore.metaMask.getBalance(
          tokenAddress,
          this.assetSend.decimals
        );

        runInAction(() => {
          this.balance = formattedBalance || defaultBalance;
          this.isSendAllowed = isSendAllowed;
        });
      }

      if (isXrpWallet(this.chainFromWallet.key)) {
        const xummBalance = await this.rootStore.xumm.getBalance(
          this.assetSend.key
        );

        runInAction(() => {
          this.balance = xummBalance || defaultBalance;
        });
      }
    } catch (error) {
      this.balance = defaultBalance;
    }
  }

  // TODO: Refactor this method, hide or remove allbridge, make more readable
  async sendAmount(receiveAmount: number) {
    if (!this.assetSend) {
      this.errors.assetSendError = "Asset is not selected.";
      return;
    }

    if (!this.amountToInputValue) {
      this.errors.assetSendError = "Amount is not entered.";
      return;
    }

    if (!receiveAmount) {
      this.errors.assetSendError = "Amount is too small.";
      return;
    }

    if (this.chainFrom == null || this.chainTo == null) {
      this.errors.assetSendError = "Chains are not selected.";
      return;
    }

    if (avaxToBnbFlow(this.chainFrom, this.chainTo) === FlowDirection.Receive) {
      this.isWaitingWallet = true;
      await this.rootStore.metaMask.transfer(receiveAmount, (txid) => {
        this.isWaitingWallet = false;
        this.setActiveStep(this.steps.confirm);
        this.sourceTransactionId = txid;
      });
      this.isReceiveCompleted = true;
      this.setActiveStep(this.steps.receive);
      return;
    }

    if (avaxToBnbFlow(this.chainFrom, this.chainTo) === FlowDirection.Send) {
      this.isWaitingWallet = true;
      await this.rootStore.metaMask.swapOut(+receiveAmount, (txid) => {
        this.isWaitingWallet = false;
        this.setActiveStep(this.steps.confirm);
        this.sourceTransactionId = txid;
      });
      this.isReceiveCompleted = true;
      this.setActiveStep(this.steps.receive);
      return;
    }

    if (!this.addressToInputValue) {
      this.errors.assetSendError = "Address is not entered.";
      return;
    }

    if (avaxToXrpFlow(this.chainFrom, this.chainTo) === FlowDirection.Receive) {
      this.isWaitingWallet = true;
      try {
        await this.rootStore.xumm.transfer(
          this.addressToInputValue,
          receiveAmount,
          this.assetSend.key,
          (txid, status) => {
            if (status === "signed") {
              this.showConfirmations = true;
              this.isWaitingWallet = false;
              this.setActiveStep(this.steps.confirm);
              this.sourceTransactionId = txid;
              this.transactionProgress = 33;
            }
            if (status === "source-confirmed") {
              this.transactionProgress = 66;
            }
            if (status === "dest-confirmed") {
              this.transactionProgress = 100;
              this.destTransactionId = txid;
            }
          }
        );
        this.isSendTxConfirmed = true;
      } catch (err) {
        this.isWaitingWallet = false;
      }

      return;
    }

    if (avaxToXrpFlow(this.chainFrom, this.chainTo) === FlowDirection.Send) {
      this.isWaitingWallet = true;
      const tokenAddress = getTokenAddress(this.assetSend, this.chainFrom);
      await this.rootStore.metaMask.oxpSwapOut(
        tokenAddress,
        this.addressToInputValue,
        receiveAmount,
        this.assetSend,
        (txid, status) => {
          if (status === "signed") {
            this.showConfirmations = true;
            this.isWaitingWallet = false;
            this.setActiveStep(this.steps.confirm);
            this.sourceTransactionId = txid;
            this.transactionProgress = 33;
          }
          if (status === "source-confirmed") {
            this.transactionProgress = 66;
          }
          if (status === "dest-confirmed") {
            this.transactionProgress = 100;
            this.destTransactionId = txid;
          }
        }
      );
      this.isSendTxConfirmed = true;
      return;
    }

    if (xrpToBnbFlow(this.chainFrom, this.chainTo) === FlowDirection.Receive) {
      const tokenAddress = getTokenAddress(this.assetSend, this.chainFrom);
      const lockFundsResult = await this.rootStore.metaMask.lockFunds(
        tokenAddress,
        this.addressToInputValue,
        this.chainTo.blockchainId,
        receiveAmount,
        (txid) => {
          this.isWaitingWallet = false;
          this.setActiveStep(this.steps.confirm);
          this.sourceTransactionId = txid;
        }
      );
      this.lockFundsResult = lockFundsResult;
      this.setActiveStep(this.steps.receive);
      return;
    }

    if (xrpToBnbFlow(this.chainFrom, this.chainTo) === FlowDirection.Send) {
      const lockFundsResult = await this.rootStore.xumm.lockFunds(
        this.addressToInputValue,
        this.chainTo.blockchainId,
        receiveAmount,
        (txid) => {
          this.isWaitingWallet = false;
          this.setActiveStep(this.steps.confirm);
          this.sourceTransactionId = txid;
        }
      );
      this.lockFundsResult = lockFundsResult;
      this.setActiveStep(this.steps.receive);
      return;
    }
  }

  // TODO: Refactor this method, hide or remove allbridge, make more readable
  async receiveAmount() {
    if (this.lockFundsResult == null) {
      this.errors.assetSendError = "Something gone wrong.";
      return;
    }

    if (this.chainFrom == null || this.chainTo == null) {
      this.errors.assetSendError = "Chains are not selected.";
      return;
    }

    const {
      lockId,
      recipient,
      amount,
      source,
      tokenSource,
      tokenSourceAddress,
      signature,
    } = this.lockFundsResult;

    this.isReceiveSubmitting = true;

    if (xrpToBnbFlow(this.chainFrom, this.chainTo) === FlowDirection.Receive) {
      await this.rootStore.xumm.unlockFunds(
        lockId,
        recipient,
        amount,
        source,
        tokenSource,
        tokenSourceAddress,
        signature
      );
      this.isReceiveSubmitting = false;
      this.isReceiveCompleted = true;
      return;
    }

    if (xrpToBnbFlow(this.chainFrom, this.chainTo) === FlowDirection.Send) {
      await this.rootStore.metaMask.unlockFunds(
        lockId,
        recipient,
        amount,
        source,
        tokenSource,
        tokenSourceAddress,
        signature
      );
      this.isReceiveSubmitting = false;
      this.isReceiveCompleted = true;
      return;
    }
  }

  async connectReceiveWallet(wallet: IWallet) {
    if (!wallet || this.isReceiveWalletConnected()) return;
    this.chainToWallet = wallet;
    if (this.chainTo?.chainData) {
      await this.rootStore.metaMask.init(
        this.chainTo.chainData,
        this.chainToWallet.key === BridgeWallet.walletConnect
      );
      await this.rootStore.metaMask.signIn();
    }
  }

  async connectPendingTransactionReceiveWallet(wallet: IWallet) {
    await this.connectReceiveWallet(wallet);
    this.isConnectToWalletAllowed = false;
    this.isTransferSubmitting = true;
  }

  // todo: move outside component logic
  async checkTransactionByID() {
    if (!this.transferToInputValue) return;
    try {
      const { data } = await axios.get(
        allBridgeSignEndpoint + this.transferToInputValue
      );
      this.lockFundsResult = data;
      this.savedFinishedChain = data.destination;
      this.isConnectToWalletAllowed = true;

      this.chainTo = chainsData.find(
        (ch) => ch.blockchainId === this.savedFinishedChain
      );
      this.chainFrom = chainsData.find(
        (ch) => ch.blockchainId === this.lockFundsResult?.source
      );
      this.chainFromWallet = walletsData.find(
        (wl) => wl.key === this.chainFrom?.wallets[0]
      );
    } catch (err) {
      this.errors.transferToInputError =
        (err as any)?.response?.data?.message ||
        "The assets have been already received.";
      this.isConnectToWalletAllowed = false;
    }
  }

  async transferReceive() {
    await this.receiveAmount();
    this.isTransferCompleted = true;
  }

  isReceiveWalletConnected() {
    if (this.chainTo?.key === BridgeChain.xrpLedger) {
      return true;
    }
    return this.rootStore.metaMask.userAddress != null;
  }

  setActiveStep(step: number) {
    this.activeStep = step;
    this.checkStepCondition();
  }

  checkStepCondition() {
    if (this.activeStep === this.steps.address) {
      this.getFees();
    }
  }

  private async getFees() {
    if (!this.chainFrom?.multichain || !this.chainTo?.multichain || !this.assetSend?.multichain || !this.assetReceive?.multichain) return;

    try {
      const chainId = this.chainFrom?.multichain.id;
      const tokenAddress = this.assetSend?.multichain.address;
      const destChainId = this.chainTo.multichain.id;
      const destTokenAddress = this.assetReceive.multichain.address;

      const chainData = await axios.get(anySwapAPI + "v4/tokenlistv4/" + chainId);

      const sendTokenData: any = chainData.data
        ? Object.values(chainData.data).find((token: any) => token.address === tokenAddress)
        : {}

      const destChains = sendTokenData?.destChains?.[destChainId] || {};
      const destTokenData: any = Object.values(destChains).find((chain: any) => chain.address === destTokenAddress);

      if (!destTokenData) {
        console.error(destTokenData)
        throw new Error("Something went wrong, please try later!");
      }

      const transferFee: txOptionsType = {
        feePercentage: Number(destTokenData.MinimumSwapFee) !== Number(destTokenData.MaximumSwapFee) ? Number(destTokenData.SwapFeeRatePerMillion) : 0,
        feeMinAmount: Number(destTokenData.MinimumSwapFee),
        feeMaxAmount: Number(destTokenData.MaximumSwapFee),
        minAmount: Number(destTokenData.MinimumSwap),
        maxAmount: Number(destTokenData.MaximumSwap),
        estimatedTime: "10",
        bigAmount: Number(destTokenData.BigValueThreshold),
      }

      runInAction(() => {
        this.txOptions = transferFee;
      })

    } catch (error) {
      console.error(error)
      throw new Error("Something went wrong, please try later!");
    }
  }

  setBridgeMode(value: boolean) {
    this.isBridgeMode = value;
  }

  setChainFrom(chain: IChainData) {
    if (!chain) return;
    this.chainFrom = chain;
    this.setDefaultAssets();
  }

  setChainTo(chain: IChainData) {
    if (!chain) return;
    this.chainTo = chain;
    this.setDefaultAssets();
  }

  setSendAsset(asset: IAssetData) {
    if (!asset) return;
    this.assetSend = asset;
    this.errors.assetSendError = undefined;

    this.setReceiveAsset();
  }

  setReceiveAsset() {
    if (!this.chainFrom || !this.chainTo || !this.assetSend) {
      this.assetReceive = undefined;
      return;
    }

    const assets = this.chainFrom.assets[this.chainTo.key]?.allowedSendAssets;
    const receiveAssets =
      assets?.[this.assetSend.key]?.allowedReceiveAsset || {};
    const receiveAssetKey =
      Object.values(receiveAssets).length > 0
        ? Object.values(receiveAssets)[0].receivedAssetKey
        : "";
    const receiveAsset = assetsData.find(
      (_asset) => _asset.key === receiveAssetKey
    );

    return (this.assetReceive = receiveAsset ? receiveAsset : undefined);
  }

  setDefaultAssets() {
    if (
      !this.chainFrom ||
      !this.chainTo ||
      !this.chainFrom.assets ||
      !this.chainTo.key
    ) {
      this.assetSend = undefined;
      this.assetReceive = undefined;

      return;
    }

    // const assets = this.chainFrom?.assets[this.chainTo?.key].allowedSendAssets;
    const assets = this.chainFrom.assets[this.chainTo.key]?.allowedSendAssets;

    if (assets && Object.keys(assets).length > 0) {
      const sendAssetKey = Object.keys(assets)[0];
      const assetSend = assetsData.find((asset) => asset.key === sendAssetKey);
      if (assetSend) {
        this.setSendAsset(assetSend);
      } else {
        this.assetSend = undefined;
        this.assetReceive = undefined;
      }
    } else {
      this.assetSend = undefined;
      this.assetReceive = undefined;
    }
  }

  switchChains() {
    if (!this.chainFrom || !this.chainTo) return;
    this.disconnectWallet();
    const _chainFrom = this.chainFrom;
    const _chainTo = this.chainTo;
    const _assetSend = this.assetSend;
    const _assetReceive = this.assetReceive;
    this.chainTo = _chainFrom;
    this.chainFrom = _chainTo;
    this.assetSend = _assetReceive;
    this.assetReceive = _assetSend;
  }

  setChainFromWallet(wallet: IWallet) {
    if (!wallet) return;
    this.chainFromWallet = wallet;
    this.connectWallet();
  }

  setTransferToInputValue(value: string) {
    this.transferToInputValue = value;
    this.errors.transferToInputError = undefined;
  }

  setAddressToInputValue(value: string) {
    this.addressToInputValue = value;
    this.validateAddress(value);
  }

  validateAddress(address: string) {
    if (this.chainTo?.key === BridgeChain.xrpLedger) {
      this.errors.addressToInputError = this.isValidXRPAddress(address)
        ? ""
        : "This is not a valid XRPL address";
    }

    if (
      this.chainTo?.key === BridgeChain.avax ||
      this.chainTo?.key === BridgeChain.ethereum
    ) {
      this.errors.addressToInputError = utils.isAddress(address)
        ? ""
        : "This is not a valid ERC20 address";
    }
  }

  /**
   * Validates that a given address is a valid X-Address or a valid classic
   * address.
   *
   * @param address - Address to validate.
   * @returns True if address is a valid X-Address or classic address.
   * @category Utilities
   */
  private isValidXRPAddress(address: string): boolean {
    return isValidXAddress(address) || isValidClassicAddress(address);
  }

  setAmountToInputValue(value: string) {
    this.amountToInputValue = value;
    this.errors.amountToInputError = undefined;
  }

  setMaxBalance() {
    this.amountToInputValue = this.balance;
  }

  getActiveAssets() {
    if (!this.chainFrom || !this.chainTo) return [];

    const allowedSendAssets =
      this.chainFrom.assets?.[this.chainTo.key]?.allowedSendAssets;

    if (!allowedSendAssets) return [];

    const allowedAssetsKeys = Object.keys(allowedSendAssets);

    const assets = assetsData.filter((asset) =>
      allowedAssetsKeys.includes(asset.key)
    );

    return assets
      .map((asset) => {
        const _asset = { ...asset };
        if (allowedSendAssets[asset.key]?.isDisable) {
          _asset.disabled = true;
        }

        return _asset;
      })
      .sort((_, b) => (b.disabled ? -1 : 1));
  }

  getActiveWallets({ isReceive }: { isReceive?: boolean }) {
    if (!this.chainFrom?.key || !this.chainTo?.key) return [];

    const allowedWallets = !isReceive
      ? this.chainFrom.wallets
      : this.chainTo.wallets;

    return walletsData
      .map((wallet) => {
        const _wallet = { ...wallet };
        if (allowedWallets.includes(wallet.key)) {
          if (wallet.key === BridgeWallet.metaMask) {
            _wallet.active = this.rootStore.metaMask.isMetaskAvailable();
          } else {
            _wallet.active = true;
          }
        }

        return _wallet;
      })
      .sort((_, b) => (b.active ? 1 : -1));
  }

  sendStepCompleted() {
    this.setActiveStep(this.steps.receive);
    this.isReceiveCompleted = true;
  }

  setInitialState() {
    this.addressToInputValue = "";
    this.amountToInputValue = "";
    this.sourceTransactionId = "";
    this.destTransactionId = undefined;
    this.transferToInputValue = "";
    this.isBridgeMode = true;
    this.isReceiveCompleted = false;
    this.isConnectToWalletAllowed = false;
    this.isTransferCompleted = false;
    this.isTransferSubmitting = false;
    this.isSendTxConfirmed = false;
    this.isWaitingWallet = false;
    this.isReceiveSubmitting = false;
  }

  bridgeAgain() {
    this.setInitialState();
    this.setActiveStep(this.steps.chains);
  }

  closeWaitingWallet() {
    this.isWaitingWallet = false;
  }

  canAddTokenToWallet() {
    return (
      this.isWalletConnected &&
      (this.chainFrom?.key === BridgeChain.ethereum ||
        this.chainFrom?.key === BridgeChain.bnbChain ||
        this.chainFrom?.key === BridgeChain.avax)
    );
  }

  async addTokenToWallet() {
    if (!this.assetSend) {
      this.errors.assetSendError = "Asset is not selected.";
      return;
    }
    if (this.chainFrom == null || this.chainTo == null) {
      this.errors.assetSendError = "Chains are not selected.";
      return;
    }
    if (
      this.chainFrom?.key === BridgeChain.ethereum ||
      this.chainFrom?.key === BridgeChain.bnbChain ||
      this.chainFrom?.key === BridgeChain.avax
    ) {
      await this.rootStore.metaMask.addTokenToWallet(
        this.assetSend,
        this.chainFrom
      );
    }
  }

  async approveTokensToSpend() {
    if (!this.assetSend) {
      this.errors.assetSendError = "Asset is not selected.";
      return;
    }
    if (this.chainFrom == null || this.chainTo == null) {
      this.errors.assetSendError = "Chains are not selected.";
      return;
    }
    if (this.chainFromWallet == null) {
      this.errors.assetSendError = "Wallets are not selected.";
      return;
    }

    if (isEthWallet(this.chainFromWallet.key)) {
      this.isApproveButtonLoading = true;
      const tokenAddress = getTokenAddress(this.assetSend, this.chainFrom);
      const { formattedBalance } = await this.rootStore.metaMask.getBalance(
        tokenAddress,
        this.assetSend.decimals
      );

      try {
        await this.rootStore.metaMask.approveAllowedAmountOrAll(tokenAddress);
        runInAction(() => {
          this.balance = formattedBalance;
          this.isSendAllowed = true;
          this.isApproveButtonLoading = false;
        });
      } catch (error) {
        runInAction(() => {
          this.balance = formattedBalance;
          this.isSendAllowed = false;
          this.isApproveButtonLoading = false;
        });
      }
    }

    if (isXrpWallet(this.chainFromWallet.key)) {
      const balance = await this.rootStore.xumm.getBalance(this.assetSend.key);
      await this.rootStore.xumm.setTrustline(this.assetSend.key, balance);
    }
  }

  async setActiveStepAndBalanceAsync(step: number) {
    if (!this.assetSend) {
      this.errors.assetSendError = "Asset is not selected.";
      return;
    }
    if (this.chainFrom == null || this.chainTo == null) {
      this.errors.assetSendError = "Chains are not selected.";
      return;
    }

    await this.updateBalances();

    runInAction(() => {
      this.setActiveStep(step);
    });
  }

  async setTrustLine() {
    if (!this.assetSend) {
      this.errors.assetSendError = "Asset is not selected.";
      return;
    }
    if (this.chainFrom == null || this.chainTo == null) {
      this.errors.assetSendError = "Chains are not selected.";
      return;
    }
    if (this.chainFromWallet == null) {
      this.errors.assetSendError = "Wallets are not selected.";
      return;
    }

    if (isXrpWallet(this.chainFromWallet.key)) {
      this.isWaitingWallet = true;
      const balance = await this.rootStore.xumm.getBalance(this.assetSend.key);
      try {
        await this.rootStore.xumm.setTrustline(this.assetSend.key, balance);
      } catch (error) {
      } finally {
        runInAction(() => {
          this.isWaitingWallet = false;
        });
      }
    } else {
      //TODO: generate QR code to scan  like here https://xrpl.services/?issuer=rDsvn6aJG4YMQdHnuJtP9NLrFp18JYTJUf&currency=OVX&limit=100000000
      this.errors.assetSendError = "XUMM wallets is not connected.";
      return;
    }
  }
}
