import React from "react";
import { observer } from "mobx-react";

import { useAppState } from "../stores";
import SelectAssetsOption from "./SelectAssetsOption";

interface SelectReceiveAssetValueProps {
}

const SelectReceiveAssetValue: React.FC<SelectReceiveAssetValueProps> = observer(() => {
  const { bridge: { assetReceive } } = useAppState();

  return (
    <>
      {assetReceive &&
        <SelectAssetsOption asset={assetReceive} />
      }
    </>
  );
});

export default SelectReceiveAssetValue;
