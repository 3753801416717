import React from "react";
import { observer } from "mobx-react";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useAppState } from "../stores";

const inputWrapperSx = {
  position: "relative",
  width: "100%",
};

const inputSx = {
  background: "transparent",
  border: "1px solid #c2c2c8",
  borderRadius: 24,
  padding: { xs: "10px 30% 10px 10px", sm: "15px 35% 15px 40px" },
  height: { xs: "40px", sm: "48px" },
  "& input": {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 700,
    color: "gray.darker",
    padding: 0,
  },
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "input[type=number]": {
    MozAppearance: "textfield",
  },
  "&.error": {
    input: {
      color: "red.main",
    },
  },
};

const buttonWrapperSx = {
  position: "absolute",
  top: 0,
  right: 0,
  height: "100%",
  width: { xs: "auto", sm: "35%" },
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "4px",
  button: {
    height: "100%",
  },
} as any;

interface AmountToInputProps {
  isError?: boolean;
}

const AmountToInput: React.FC<AmountToInputProps> = observer(({ isError }) => {
  const {
    bridge: {
      setAmountToInputValue,
      setMaxBalance,
      amountToInputValue,
      errors: { amountToInputError },
    },
  } = useAppState();

  const onChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const rgx = /^[0-9]*[.,]?[0-9]*$/;
    let _value = e.target.value;
    if (!rgx.test(_value)) return;
    _value = _value.replace(/,/g, ".");

    setAmountToInputValue(_value);
  };

  return (
    <>
      <Box sx={inputWrapperSx}>
        <Input
          type="text"
          name="amount"
          placeholder="Enter tokens... "
          disableUnderline
          fullWidth
          sx={inputSx}
          className={amountToInputValue && isError ? "error" : ""}
          onChange={onChangeInput}
          value={amountToInputValue}
          inputProps={{
            pattern: "^[0-9]*[.]?[0-9]*$",
            minLength: "1",
            maxLength: "79",
            inputMode: "decimal",
            autoCorrect: "off",
            spellCheck: "false",
          }}
        />
        <Box sx={buttonWrapperSx}>
          <Button color="secondary" variant="contained" size="large" onClick={() => setMaxBalance()}>
            Max
          </Button>
        </Box>
      </Box>
      {amountToInputError && (
        <Box mt={"4px"} pl={"40px"} textAlign="left">
          <Typography
            sx={{
              fontSize: "0.75rem",
              fontWeight: 700,
              lineHeight: "1rem",
              color: "text.error",
            }}
          >
            {amountToInputError}
          </Typography>
        </Box>
      )}
    </>
  );
});

export default AmountToInput;
