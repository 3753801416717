import { ethers, utils, BigNumber } from "ethers";

import erc20Abi from "../../../assets/contracts/ERC20.json";

export class Erc20Helper {
    private provider: ethers.providers.Web3Provider;
    private contractAddress: string;

    constructor(provider: ethers.providers.Web3Provider, contractAddress?: string) {
        if (contractAddress == null) {
            throw new Error("Contract address should be defined in env variables!")
        }
        this.contractAddress = contractAddress;
        this.provider = provider;
    }

    async isAllowed(tokenAddress: string, userAddress: string) {
        try {
            const contract = new ethers.Contract(tokenAddress, erc20Abi, this.provider?.getSigner());
            const allowedTokensAmount = await contract.allowance(userAddress, this.contractAddress);
            const formattedAmount = utils.formatEther(allowedTokensAmount);
            const { balance } = await this.getBalance(tokenAddress, userAddress);
            return allowedTokensAmount.gt(0) && allowedTokensAmount.gte(balance) ;
        }
        catch (err) {
            throw err;
        }
    }

    async approveAllowedAmountOrAll(tokenAddress: string, userAddress: string, amount?: number) {
        try {
            const contract = new ethers.Contract(tokenAddress, erc20Abi, this.provider?.getSigner());
            // let amountToAllow;
            // if (amount != null) {
            //     amountToAllow = utils.parseEther(amount.toString());
            // } else {
            //     const { balance } = await this.getBalance(tokenAddress, userAddress);
            //     amountToAllow = balance;
            // }
            //TODO for now use max big number 
            const transaction = await contract.approve(this.contractAddress, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");

            await transaction.wait(1);
        }
        catch (err) {
            throw err;
        }
    }

    async getBalance(tokenAddress: string, userAddress: string, units: number = 6) {
        try {
            const contract = new ethers.Contract(tokenAddress, erc20Abi, this.provider?.getSigner());
            const balance = await contract.balanceOf(userAddress);
            const formattedBalance = utils.formatUnits(balance, units);
            return {
                balance,
                formattedBalance,
            };
        }
        catch (err) {
            throw err;
        }
    }
}