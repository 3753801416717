import React, { useMemo } from "react";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";

import { ReactComponent as CloseIcon } from "../assets/images/close.svg";
import { useAppState } from "../stores";
import { IWallet } from "../assets/types";

interface SelectWalletOptionsProps {
  handleSelect: (wallet: IWallet) => void;
  handleClose?: () => void;
  isReceive?: boolean;
}

const SelectWalletOptions: React.FC<SelectWalletOptionsProps> = ({
  handleSelect,
  handleClose,
  isReceive,
}) => {
  const {
    bridge: { getActiveWallets },
  } = useAppState();
  const wallets = useMemo(
    () => getActiveWallets({ isReceive }),
    [getActiveWallets, isReceive]
  );
  const onSelectWallet = (wallet: IWallet) => {
    if (!wallet.active) return;

    handleSelect(wallet);
    if (typeof handleClose === "function") {
      handleClose();
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="baseline"
          pb={3}
        >
          <Grid item>
            <Typography variant="h3">Connect to a wallet</Typography>
          </Grid>
          <Grid item sx={{ display: "flex" }}>
            <CloseIcon
              onClick={handleClose}
              height={16}
              width={16}
              cursor="pointer"
            />
          </Grid>
        </Grid>
        <Box>
          <List sx={{ maxHeight: "300px" }}>
            {wallets.filter(item => item?.active).map((item) => (
              <ListItem
                key={item.name}
                sx={{ p: 0, mb: 2 }}
                onClick={() => onSelectWallet(item)}
              >
                <ListItemButton
                  disabled={!item.active}
                  sx={(theme) => ({
                    border: "1px solid",
                    borderRadius: "24px",
                    borderColor: "#eee",
                    padding: "20px 24px",
                    "&:hover": {
                      background:
                        theme.palette.mode !== "dark" ? "#e2e2ed" : "#e5e5e5",
                      ...(!item.active && {
                        opacity: "0.7",
                        "&:hover": {
                          background: "none",
                        },
                      }),
                    },
                  })}
                >
                  <ListItemIcon
                    sx={{
                      "& img": {
                        maxWidth: "25px",
                        maxHeight: "25px",
                        width: "100%",
                        height: "100%",
                      },
                    }}
                  >
                    {item?.img ? (
                      <img
                        src={"/assets/images/wallet-icons/" + item.img}
                        alt={item.name}
                      />
                    ) : (
                      <img
                        src={"/assets/images/icons/unknown-icon.svg"}
                        alt={item.name}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </>
  );
};

export default SelectWalletOptions;
