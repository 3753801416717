import { Axios } from "axios";
import { io, Socket } from "socket.io-client";
import { BridgeAsset } from "../../../assets/types";
import { getXrpCurrencyByAsset } from "./wallets";

export type OnSignedInCallback = (account: string, userToken: string) => void;
export type OnShowQRCodeCallback = () => void;
export type TransactionResultHandler = (data: any) => boolean;
export type OnQrDataRetrievedCallback = (qrImage: string, mobileLink: string) => void;

export class XummWallet {
    private axios: Axios;
    private socket: Socket;
    private onQrDataRetrieved: OnQrDataRetrievedCallback;
    protected usePushNotifications: boolean;

    constructor(onQrDataRetrieved: OnQrDataRetrievedCallback, usePushNotifications: boolean) {
        this.onQrDataRetrieved = onQrDataRetrieved;
        this.usePushNotifications = usePushNotifications;
        this.axios = new Axios({
            baseURL: process.env.REACT_APP_AXIOS_ROOT_URL as string,
        });
        this.socket = io(process.env.REACT_APP_AXIOS_ROOT_URL as string, {
            // @ts-ignore
            'sync disconnect on unload': true,
            transports: ["websocket"],
        });
    }

    async signIn(onSignedInCallback: OnSignedInCallback, onShowQrCode?: OnShowQRCodeCallback) {
        try {
            const transaction = {
                "txjson": {
                    "TransactionType": 'SignIn',
                },
                "options": {
                    "submit": true,
                    "multisign": false,
                    "expire": 15,
                },
            };
            if (onShowQrCode != null) {
                onShowQrCode();
            }
            const response = await this.postTransactionAndSubscribe(transaction, (data: any) => {
                const account = data?.payload?.response?.account;
                if (account != null) {
                    onSignedInCallback(account, data.payload.application.issued_user_token);
                    return true;
                }
                return false;
            });
        }
        catch (err) {
            throw err;
        }
    }

    async setTrustline(account: string, asset: BridgeAsset, amount: number, userToken?: string) {
        try {
            const transaction: any = {
                "txjson": {
                    "TransactionType": "TrustSet",
                    "SourceTag": 69420589,
                    "Account": account,
                    "LimitAmount": getXrpCurrencyByAsset(asset, amount),
                    "Flags": 131072 // TODO Is it common value for all tokens ?
                },
                "options": {
                    "submit": true,
                    "multisign": false,
                    "expire": 15,
                },
            };

            if (this.usePushNotifications && userToken != null) {
                transaction["user_token"] = userToken;
            }

            const response = await this.postTransactionAndSubscribe(transaction, () => true);
        }
        catch (err) {
            throw err;
        }
    }

    async postTransactionAndSubscribe(transaction: any, transactionResultHandler: TransactionResultHandler) {
        const response = await this.axios.post(`/api/account/create-transaction`, JSON.stringify(transaction), {
            headers: {
                "Content-Type": "application/json;charset=utf-8"
            }
        });
        const { created: { uuid, pushed, refs: { qr_png }, next: { always } } } = JSON.parse(response.data);
        this.socket.emit("subscribe-uuid", uuid);

        if (!pushed) {
            this.onQrDataRetrieved(qr_png, always);
        }

        const transactionResult: any = await new Promise((resolve, reject) => {
            const handleData = (data: any) => {
                const result = transactionResultHandler(data);
                if (result && data.payload != null) {
                    if (!data.payload.meta.signed || data.payload.meta.expired) {
                        reject(new Error("User declined transaction!"));
                    }
                    this.socket.off(transaction.txjson.TransactionType);
                    resolve(data);
                }
            }
            this.socket.on(transaction.txjson.TransactionType, handleData);
            this.socket.on("disconnect", reason => {
                if (reason === 'transport error') {
                    this.socket.connect();
                    this.axios.get(`/api/account/get-transaction-status/${uuid}`).then(response => {
                        handleData(JSON.parse(response.data));
                    });
                }
            });
        });

        return transactionResult;
    }
}