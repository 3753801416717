import { BridgeWallet, IWallet } from "./types";

const walletsData: IWallet[] = [
    {
        key: BridgeWallet.metaMask,
        name: "MetaMask",
        img: "metamask.svg"
    },
    {
        key: BridgeWallet.xumm,
        name: "Xumm",
        img: "xumm.svg"
    },
    {
        key: BridgeWallet.walletConnect,
        name: "WalletConnect",
        img: "walletConnect.svg"
    }
]

export default walletsData;