import React, { useState } from "react";
import { Box, Modal, Backdrop, Fade, Button } from "@mui/material";

const modalBodyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 40px)",
  maxWidth: "500px",
  bgcolor: "background.modalBody",
  borderRadius: "24px",
  padding: "32px 32px 16px",
};

interface ConnectWalletsProps {
  children?: React.ReactChild | React.ReactChild[];
  variant?: "onxrpPrimary" | "onxrpSecondary";
  fullWidth?: boolean;
}

const ConnectWallets: React.FC<ConnectWalletsProps> = ({ children, variant, fullWidth }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const props = { handleClose } as any
      return React.cloneElement(child, props);
    }

    return child;
  });

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Button
          color="primary"
          variant={variant || "contained"}
          size="large"
          onClick={handleOpen}
          fullWidth={fullWidth}
        >
          Connect Wallet
        </Button>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal-body"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: {
            opacity: "0.9",
          },
          sx: {
            backgroundColor: "background.backDrop",
          },
        }}
        disableAutoFocus
      >
        <Fade in={open}>
          <Box sx={modalBodyStyle}>{childrenWithProps}</Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ConnectWallets;
