import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";

import RootStore from "./index";
import { LockFundsResult, SupportedTokenSource, TransactionStatusChangedCallback } from "./bridgeHelpers/utils/wallets";
import { AllbridgeXrplXumm } from "./bridgeHelpers/allbridgeXrplXumm";
import { OnShowQRCodeCallback } from "./bridgeHelpers/utils/xummWallet";
import { MultichainXumm } from "./bridgeHelpers/multichainXumm";
import { BridgeAsset } from "../assets/types";

export default class XummWalletStore {
  public rootStore: RootStore;
  public isSigningIn: boolean = false;
  public qrCodeImg?: string;
  public userAddress?: string;
  public userToken?: string;
  public mobileLink?: string;
  public isLoggedIn: boolean = false;

  private allbridgeXrplXumm: AllbridgeXrplXumm;
  private multichainXumm: MultichainXumm;
  private useMultichain: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: "XummWalletStore",
      properties: ["userToken", "userAddress", "isLoggedIn"],
      storage: window.localStorage,
      expireIn: 3600000,
    });

    this.rootStore = rootStore;

    this.allbridgeXrplXumm = new AllbridgeXrplXumm((qrImage: string, mobileLink: string) => {
      runInAction(() => {
        this.isSigningIn = true;
        this.qrCodeImg = qrImage;
        this.mobileLink = mobileLink;
      });
    }, true);

    this.multichainXumm = new MultichainXumm((qrImage: string, mobileLink: string) => {
      runInAction(() => {
        this.isSigningIn = true;
        this.qrCodeImg = qrImage;
        this.mobileLink = mobileLink;
      });
    }, true);
  }

  async signIn(onShowQrCode?: OnShowQRCodeCallback) {
    try {
      if (!this.isLoggedIn) {
        await this.allbridgeXrplXumm.signIn((account: string, userToken: string) => {
          runInAction(() => {
            this.isSigningIn = false;
            this.userAddress = account;
            this.userToken = userToken;
            this.isLoggedIn = true;
          });
        }, onShowQrCode);
      } else {
        if (onShowQrCode != null) {
          onShowQrCode();
        }
      }
    } catch (err) {
      this.rootStore.states.handleError(err);
      throw err;
    }
  }

  async setTrustline(asset: BridgeAsset, amount: number) {
    try {
      if (this.userAddress == null) {
        return;
      }
      await this.allbridgeXrplXumm.setTrustline(this.userAddress, asset, 100000000, this.userToken);
    } catch (err) {
      this.rootStore.states.handleError(err);
      throw err;
    }
  }

  async disconnect() {
    this.userToken = undefined;
    this.userAddress = undefined;
    this.isLoggedIn = false;
  }

  async lockFunds(
    recipient: string,
    destination: SupportedTokenSource,
    amount: number,
    onTransactionStatusChanged?: TransactionStatusChangedCallback
  ): Promise<LockFundsResult> {
    try {
      return await this.allbridgeXrplXumm.lockFunds(
        recipient,
        destination,
        amount,
        this.userToken,
        onTransactionStatusChanged
      );
    } catch (err) {
      this.rootStore.states.handleError(err);
      throw err;
    }
  }

  async unlockFunds(
    lockId: string,
    recipient: string,
    amount: string,
    lockSource: SupportedTokenSource,
    tokenSource: SupportedTokenSource,
    tokenSourceAddress: string,
    signature: string
  ) {
    try {
      return await this.allbridgeXrplXumm.unlockFunds(
        lockId,
        recipient,
        amount,
        lockSource,
        tokenSource,
        tokenSourceAddress,
        signature
      );
    } catch (err) {
      this.rootStore.states.handleError(err);
      throw err;
    }
  }

  // TODO: refactor this
  async transfer(
    recipient: string,
    amount: number,
    asset: BridgeAsset,
    onTransactionStatusChanged?: TransactionStatusChangedCallback
  ) {
    try {
      return await this.multichainXumm.transfer(recipient, amount, asset, this.userToken, onTransactionStatusChanged);
    } catch (err) {
      this.rootStore.states.handleError(err);
      throw err;
    }
  }

  async getBalance(asset: BridgeAsset) {
    try {
      if (this.userAddress != null && this.useMultichain) {
        return await this.multichainXumm.getBalance(this.userAddress, asset);
      }
    } catch (err) {
      this.rootStore.states.handleError(err);
      throw err;
    }
  }

  toggleMultichain(isEnabled: boolean) {
    this.useMultichain = isEnabled;
  }

  resetSignIn() {
    this.isSigningIn = false;
    this.qrCodeImg = undefined;
    this.isLoggedIn = false;
  }
}
