import React, { useState } from "react";
import { Box, Modal, Backdrop, Fade, useTheme } from "@mui/material";

import { ReactComponent as ArrowDownIcon } from "../assets/images/arrowDown.svg";
import { ReactComponent as ArrowDownDarkIcon } from "../assets/images/arrowDownDark.svg";

const modalBodyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 40px)",
  maxWidth: "500px",
  bgcolor: "background.modalBody",
  borderRadius: "24px",
  padding: "32px 32px 16px",
};

const inputStyle = (theme: any) => ({
  position: "relative",
  display: "flex",
  height: { xs: "40px", sm: "48px" },
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  padding: { xs: "0 12px", md: "0 48px 0 24px" },
  borderRadius: "24px",
  background: "none",
  border: "1px solid",
  borderColor: "border.main",
  cursor: "pointer",
  "& span": {
    position: "absolute",
    display: "flex",
    height: "100%",
    top: 0,
    right: "24px",
    alignItems: "center",
  },
});

type valueType = string | number | React.ReactNode | null;
interface SelectProps {
  value?: valueType;
  children?: React.ReactChild | React.ReactChild[];
  onSelect?: (selectedItem: any) => void;
}

const Select: React.FC<SelectProps> = ({ value, children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const props = { handleClose } as any
      return React.cloneElement(child, props);
    }

    return child;
  });

  return (
    <>
      <Box sx={{ width: "100%" }} onClick={handleOpen}>
        <Box sx={inputStyle}>
          <Box>{value}</Box>
          <Box component="span">
            {theme.palette.mode !== "dark" ? (
              <ArrowDownIcon />
            ) : (
              <ArrowDownDarkIcon />
            )}
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal-body"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: {
            opacity: "0.9",
          },
          sx: {
            backgroundColor: "background.backDrop",
          },
        }}
        disableAutoFocus
      >
        <Fade in={open}>
          <Box sx={modalBodyStyle}>{childrenWithProps}</Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Select;
