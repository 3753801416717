import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

import RootStore from "./index";
import { copyToClipboard } from "../utils/formatter";

export default class States {
    public rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootStore = rootStore;
    }

    handleError(error: any) {
        const message = error.message;
        toast(message, { type: toast.TYPE.ERROR, autoClose: 8000 });

        // throw error;
    }

    copyToClipboard(text: string) {
        copyToClipboard(text);

        toast("Copied!", { type: toast.TYPE.SUCCESS, autoClose: 8000 });
    }
}