import React from "react";
import { observer } from "mobx-react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useTheme from "@mui/material/styles/useTheme";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Theme } from "@mui/material/styles";
import { ReactComponent as CompletedIcon } from "../assets/images/completed.svg";
import { ReactComponent as CompletedDarkIcon } from "../assets/images/completedDark.svg";

import { ReactComponent as ReceiveMoneyIcon } from "../assets/images/receiveMoney.svg";
import { ReactComponent as ReceiveMoneyDarkIcon } from "../assets/images/receiveMoneyDark.svg";
import { useAppState } from "../stores";
import TransferToInput from "./TransferToInput";
import ConnectWallets from "./ConnectWallets";
import SelectWalletOptions from "./SelectWalletOptions";

const formWrapper = (theme: Theme) => ({
  padding: { xs: "24px", sm: "24px 48px" },
  marginBottom: "24px",
  fontFamily: `"Kallisto", "Helvetica", "Arial", sans-serif`,
  fontWeight: "bold",
  background: "transparent",
  color: theme.palette.button.text,
  border: "1px solid",
  borderColor: "border.main",
  borderRadius: "24px",
});

const iconWrapperSx = (theme: Theme) => ({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100px",
  height: "100px",
  border: "1px solid",
  borderRadius: "50%",
  color: theme.palette.text.link,
  borderColor: theme.palette.text.link,
});

const finishButton = (theme: any) => ({
  background: "#aa3b1a",
  boxShadow: "none",
  padding: "5px 15px",
  fontSize: { xs: "0.625rem", sm: "0.875rem" },
  fontWeight: 400,
  lineHeight: 1.2,
  textTransform: "capitalize",
  color: theme.palette.text.button,
  "&:hover": {
    background: "#aa3b1a",
    opacity: 0.9,
  },
});

interface FinishTransferProps {
}

const FinishTransfer: React.FC<FinishTransferProps> = observer(() => {
  const {
    bridge: {
      isBridgeMode,
      setBridgeMode,
      checkTransactionByID,
      connectPendingTransactionReceiveWallet,
      transferReceive,
      bridgeAgain,
      transferToInputValue,
      isTransferSubmitting,
      isTransferCompleted,
      isConnectToWalletAllowed,
    },
  } = useAppState();
  const theme = useTheme();

  const onSelectWallet = (wallet: any) => {
    connectPendingTransactionReceiveWallet(wallet)
  }

  if (isBridgeMode) {
    return (<></>)
  }

  return (
    <>
      {!isTransferSubmitting ? (
        <Box sx={formWrapper}>
          <Grid container rowSpacing={3} mt={0}>
            <Grid container mb={2}>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <Box>
                  <Button variant="text" sx={finishButton} onClick={() => setBridgeMode(true)}>
                    Bridge
                  </Button>
                </Box>
              </Grid>
              <Typography align="left" mb={1}>
                Transaction ID to check transaction
              </Typography>
              <TransferToInput />

            </Grid>
            <Grid container>
              {!isConnectToWalletAllowed &&
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => checkTransactionByID()}
                  >
                    Check transaction
                  </Button>
                </Grid>
              }

              {isConnectToWalletAllowed && <Grid item xs={12}>
                <ConnectWallets>
                  <SelectWalletOptions handleSelect={onSelectWallet} isReceive={true} />
                </ConnectWallets></Grid>}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <>
          <Box sx={formWrapper}>
            <Grid container rowSpacing={3} mt={0}>
              <Grid container mb={2}>
                <Typography align="left" mb={1}>
                  Input transaction id
                </Typography>
                <Typography align="right" sx={{ fontSize: { xs: "0.5rem", sm: "0.8rem" } }} mb={1}>
                  {transferToInputValue}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box sx={formWrapper}>
            <Grid container rowSpacing={3} mt={0}>
              <Grid container mb={3}>
                <Typography
                  variant="h4"
                  align="left"
                  sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
                >
                  Receive money
                </Typography>
              </Grid>

              {isTransferCompleted ? (
                <Grid container justifyContent={"center"}>
                  <Grid item xs={12} mb={2}>
                    <Box sx={iconWrapperSx}>
                      {theme.palette.mode !== "dark" ? (
                        <CompletedIcon />
                      ) : (
                        <CompletedDarkIcon />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <Typography fontSize={"1.5rem"}>
                      Transaction is successful!
                    </Typography>
                    <Typography>
                      You've received{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth
                      onClick={() => bridgeAgain()}
                    >
                      Bridge again
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container justifyContent="center" pt={2}>
                  <Grid container justifyContent="center" mb={2}>
                    <Grid item xs={12}>
                      <Box sx={iconWrapperSx} mb={2}>
                        {theme.palette.mode !== "dark" ? (
                          <ReceiveMoneyIcon />
                        ) : (
                          <ReceiveMoneyDarkIcon />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                      <Typography component="h5" fontSize="1rem">
                        Submit receive transaction to finish your transfer.
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        color="secondary"
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={() => transferReceive()}
                      >
                        Receive
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
});

export default FinishTransfer;
