import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Grid,
  Typography,
  Input,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";

import { ReactComponent as CloseIcon } from "../assets/images/close.svg";
import SelectAssetsOption from "./SelectAssetsOption";
import { useAppState } from "../stores";
import { IAssetData } from "../assets/types";

interface SelectAssetsOptionsProps {
  children?: React.ReactChild | React.ReactChild[];
  handleSelect: (asset: IAssetData) => void;
  handleClose?: () => void;
}

const SelectAssetsOptions: React.FC<SelectAssetsOptionsProps> = ({
  handleSelect,
  handleClose,
}) => {
  const {
    bridge: { getActiveAssets },
  } = useAppState();
  const [phrase, setPhrase] = useState<string>("");
  const assets = useMemo(() => getActiveAssets(), [getActiveAssets]);
  const assetsFiltered = useMemo(() => {
    return phrase.length > 0
      ? assets.filter((asset) =>
        asset.name.toLowerCase().includes(phrase.toLowerCase())
      )
      : assets;
  }, [phrase, assets]);

  const onSelectChain = useCallback(
    (asset: IAssetData) => {
      if (asset.disabled) return;

      handleSelect(asset);

      if (typeof handleClose === "function") {
        handleClose();
      }
    },
    [handleClose, handleSelect]
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const searchPhrase = e.target?.value;
      setPhrase(searchPhrase);
    },
    [setPhrase]
  );

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="baseline"
          pb={3}
        >
          <Grid item>
            <Typography variant="h3">Select an asset</Typography>
          </Grid>
          <Grid item sx={{ display: "flex" }}>
            <CloseIcon
              onClick={handleClose}
              height={16}
              width={16}
              cursor="pointer"
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={2}>
          <Input
            id="blockchain-base-input"
            name="blockchain-base"
            placeholder="Type asset name"
            fullWidth
            disableUnderline
            inputProps={{
              style: {
                fontSize: "1rem",
                padding: "14px 20px",
              },
            }}
            onChange={onChange}
          />
        </Grid>
        <Box>
          <List sx={{ maxHeight: "300px", overflow: "auto" }}>
            {assetsFiltered.map((item) => (
              <ListItem
                key={item.key}
                sx={{ p: 0 }}
                onClick={() => onSelectChain(item)}
              >
                <ListItemButton
                  sx={(theme) => ({
                    "&:hover": {
                      background:
                        theme.palette.mode !== "dark" ? "#e2e2ed" : "#e5e5e5",
                    },
                    ...(item.disabled && {
                      opacity: "0.7",
                      "&:hover": {
                        background: "none",
                      },
                    }),
                  })}
                >
                  <SelectAssetsOption asset={item} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </>
  );
};

export default SelectAssetsOptions;
