import { makeAutoObservable, runInAction, toJS } from "mobx";
import { ethers, utils } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";

import RootStore from "./index";
import { getTokenAddress, LockFundsResult, SupportedTokenSource, TransactionStatusChangedCallback } from "./bridgeHelpers/utils/wallets";
import { AllbridgeEth } from "./bridgeHelpers/allbridgeEth";
import { MultichainEth } from "./bridgeHelpers/multichainEth";
import { EVMChainData, IAssetData, IChainData } from "../assets/types";

const infuraAddress = process.env.REACT_APP_INFURA;

export default class MetaMaskWalletStore {
    public rootStore: RootStore;
    public ethBalance?: string;
    public userAddress!: string;

    private provider?: ethers.providers.Web3Provider;

    private allbridgeEth!: AllbridgeEth;
    private multichainEth!: MultichainEth;
    private useMultichain: boolean = false;

    private walletConnectProvider?: WalletConnectProvider;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootStore = rootStore;
    }

    async init(chainData: EVMChainData, useWalletConnect: boolean = false) {
        if (this.provider != null) {
            await this.switchChainTo(chainData);
        }

        if (useWalletConnect && infuraAddress) {
            this.walletConnectProvider = new WalletConnectProvider({
                rpc: {
                    43114: "https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc",
                    1: infuraAddress,
                    56: "https://bsc-dataseed.binance.org/",
                }
            });
            if (this.walletConnectProvider.wc.connected) {
                await this.walletConnectProvider.wc.killSession();
            }
            await this.walletConnectProvider.enable();
            this.provider = new ethers.providers.Web3Provider(this.walletConnectProvider, "any");
        } else if (this.isMetaskAvailable()) {
            const ethereum = (window as any).ethereum;
            this.provider = new ethers.providers.Web3Provider(ethereum, "any");
        }

        await this.switchChainTo(chainData);

        if (this.provider != null) {
            this.allbridgeEth = new AllbridgeEth(this.provider, this.provider.getSigner(0));
            this.multichainEth = new MultichainEth(this.provider, this.provider.getSigner(0));
        }
    }

    async signIn() {
        try {
            let accounts: string[] | undefined;
            try {
                accounts = await this.provider?.send('eth_requestAccounts', []);
            } catch (err) {
                accounts = await this.provider?.listAccounts();
            }
            if (accounts == null) {
                return;
            }
            const balance = await this.provider?.getBalance(accounts[0]);
            if (balance != null) {
                const formattedBalance = utils.formatEther(balance);

                runInAction(() => {
                    this.ethBalance = formattedBalance;
                });
            }
            this.userAddress = accounts[0] as string;
        }
        catch (err) {
            this.rootStore.states.handleError(err);
            throw err;
        }
    }

    async disconnect() {
        try {
            await this.walletConnectProvider?.disconnect();
        }
        catch (err) {
            this.rootStore.states.handleError(err);
            throw err;
        }
    }

    // From eth to avax
    async transfer(
        amount: number,
        onTransactionStatusChanged?: TransactionStatusChangedCallback) {
        try {
            return await this.multichainEth.transfer(this.userAddress, amount, onTransactionStatusChanged);
        }
        catch (err) {
            this.rootStore.states.handleError(err);
            throw err;
        }
    }

    // From avax to eth
    async swapOut(
        amount: number,
        onTransactionStatusChanged?: TransactionStatusChangedCallback) {
        try {
            return await this.multichainEth.swapOut(this.userAddress, amount, onTransactionStatusChanged);
        }
        catch (err) {
            this.rootStore.states.handleError(err);
            throw err;
        }
    }

    async oxpSwapOut(tokenAddress: string, account: string, amount: number, assetData: IAssetData, onTransactionStatusChanged?: TransactionStatusChangedCallback) {
        try {
            return await this.multichainEth.oxpSwapOut(tokenAddress, account, amount, assetData, onTransactionStatusChanged);
        }
        catch (err) {
            this.rootStore.states.handleError(err);
            throw err;
        }
    }

    async lockFunds(
        tokenAddress: string,
        recipient: string,
        destination: SupportedTokenSource,
        amount: number,
        onTransactionStatusChanged?: TransactionStatusChangedCallback): Promise<LockFundsResult> {
        try {
            return await this.allbridgeEth.lockFunds(tokenAddress, recipient, destination, amount, onTransactionStatusChanged);
        }
        catch (err) {
            this.rootStore.states.handleError(err);
            throw err;
        }
    }

    async unlockFunds(
        lockId: string,
        recipient: string,
        amount: string,
        lockSource: SupportedTokenSource,
        tokenSource: SupportedTokenSource,
        tokenSourceAddress: string,
        signature: string) {
        try {
            await this.allbridgeEth.unlockFunds(lockId, recipient, amount, lockSource, tokenSource, tokenSourceAddress, signature);
        }
        catch (err) {
            this.rootStore.states.handleError(err);
            throw err;
        }
    }

    toggleMultichain(isEnabled: boolean) {
        this.useMultichain = isEnabled;
    }

    async isAllowed(tokenAddress: string) {
        try {
            if (this.useMultichain) {
                return await this.multichainEth.isAllowed(tokenAddress, this.userAddress);
            }
            return await this.allbridgeEth.isAllowed(tokenAddress, this.userAddress);
        }
        catch (err) {
            this.rootStore.states.handleError(err);
            throw err;
        }
    }

    async approveAllowedAmountOrAll(tokenAddress: string, amount?: number) {
        try {
            if (this.useMultichain) {
                await this.multichainEth.approveAllowedAmountOrAll(tokenAddress, this.userAddress, amount);
                return;
            }
            await this.allbridgeEth.approveAllowedAmountOrAll(tokenAddress, this.userAddress, amount);
        }
        catch (err) {
            this.rootStore.states.handleError(err);
            throw err;
        }
    }

    async getBalance(tokenAddress: string, decimals?: number) {
        try {
            if (this.useMultichain) {
                return await this.multichainEth.getBalance(tokenAddress, this.userAddress, decimals);
            }
            return await this.allbridgeEth.getBalance(tokenAddress, this.userAddress);
        }
        catch (err) {
            this.rootStore.states.handleError(err);
            throw err;
        }
    }

    async addTokenToWallet(assetData: IAssetData, chainData: IChainData) {
        const tokenAddress = getTokenAddress(assetData, chainData);

        await this.provider?.send('wallet_watchAsset', {
            type: 'ERC20',
            options: {
                address: tokenAddress,
                symbol: assetData.name,
                decimals: assetData.decimals,
                image: `https://bridge.onxrp.com/assets/images/coin-icons/${assetData.img}`,
            },
        } as any);
    }

    isMetaskAvailable() {
        return (window as any).ethereum != null;
    }

    private async switchChainTo(chainData: EVMChainData) {
        if (chainData.chainParams?.chainId == null) {
            return;
        }
        const requiredChainId = parseInt(chainData.chainParams.chainId, 16);
        const currentChainId = +(this.provider?.provider as any).networkVersion || (this.provider?.provider as any).chainId;

        if (requiredChainId === currentChainId) {
            return;
        }

        try {
            await this.provider?.send('wallet_switchEthereumChain', [{ chainId: chainData.id }]);
        } catch (error: any) {
            try {
                await this.provider?.send('wallet_addEthereumChain', [toJS(chainData.chainParams)]);
            } catch (err) {
                throw err;
            }
        }
    }
}