import axios from "axios";
import { ethers, utils } from "ethers";

import multichainAvaxAbi from "../../assets/contracts/multichainAvax.json";
import { TransactionStatusChangedCallback } from "./utils/wallets";
import { waitForConfirmation } from "./multichainUtils";
import anySwapAbi from "../../assets/contracts/anyswap-abi.json"
import { Erc20Helper } from "./utils/erc20Helper";
import { BridgeAsset, IAssetData } from "../../assets/types";


const MultichainChainId = {
    Eth: 1,
    Bsc: 56,
    Avax: 43114,
}

const InfoUrl = "https://bridgeapi.anyswap.exchange/v2/serverInfo/";

const contractAddress = "0x05f024C6F5a94990d32191D6f36211E3Ee33504e";
const xrplChainId = 1000005788240;

export class MultichainEth {
    private provider: ethers.providers.Web3Provider;
    private signer: ethers.providers.JsonRpcSigner;

    constructor(provider: ethers.providers.Web3Provider, signer: ethers.providers.JsonRpcSigner) {
        this.provider = provider;
        this.signer = signer;
    }

    // TODO: remove in future
    async transfer(account: string, amount: number, onTransactionStatusChanged?: TransactionStatusChangedCallback) {
        const config = await this.getTransferConfig();
        const srcTokenAddress = config.SrcToken.DepositAddress;

        const tx = {
            from: account,
            to: srcTokenAddress,
            value: utils.parseEther(amount.toString()),
        }

        const { hash } = await this.signer.sendTransaction(tx);

        if (onTransactionStatusChanged != null) {
            onTransactionStatusChanged(hash, 'signed');
        }

        const status = await waitForConfirmation(hash, onTransactionStatusChanged);

        return status;
    }

    // TODO: remove in future
    async swapOut(account: string, amount: number, onTransactionStatusChanged?: TransactionStatusChangedCallback) {
        const config = await this.getTransferConfig();
        const destContractAddress = config.DestToken.ContractAddress;

        const contract = new ethers.Contract(destContractAddress, multichainAvaxAbi, this.signer);

        const { hash } = await contract.Swapout(
            utils.parseEther(amount.toString()),
            account
        );

        if (onTransactionStatusChanged != null) {
            onTransactionStatusChanged(hash, 'signed');
        }

        const status = await waitForConfirmation(hash, onTransactionStatusChanged);

        return status;
    }

    async oxpSwapOut(tokenAddress: string, account: string, amount: number, assetData: IAssetData, onTransactionStatusChanged?: TransactionStatusChangedCallback) {
        const contract = new ethers.Contract(contractAddress, anySwapAbi, this.signer);
        let response;

        if (assetData.key === BridgeAsset.ovx || assetData.key === BridgeAsset.avaxOvx) {
            response = await contract["anySwapOutUnderlying(address,string,uint256,uint256)"](
                "0x3a5b423425ce1b0de7f1707271cef061605a6798",
                account,
                utils.parseUnits(amount.toString(), assetData.decimals || 6),
                xrplChainId,
            )
        } else {
            response = await contract["anySwapOut(address,string,uint256,uint256)"](
                tokenAddress,
                account,
                utils.parseUnits(amount.toString(), assetData.decimals || 6),
                xrplChainId,
            );
        }

        const { hash } = response;

        if (onTransactionStatusChanged != null) {
            onTransactionStatusChanged(hash, 'signed');
        }

        await response.wait(10);

        const status = await waitForConfirmation(hash, onTransactionStatusChanged);

        return status;
    }

    isAllowed(tokenAddress: string, userAddress: string) {
        return new Erc20Helper(this.provider, contractAddress).isAllowed(tokenAddress, userAddress);
    }

    approveAllowedAmountOrAll(tokenAddress: string, userAddress: string, amount?: number) {
        return new Erc20Helper(this.provider, contractAddress).approveAllowedAmountOrAll(tokenAddress, userAddress, amount);
    }

    getBalance(tokenAddress: string, userAddress: string, decimals?: number) {
        return new Erc20Helper(this.provider, contractAddress).getBalance(tokenAddress, userAddress, decimals);
    }

    // TODO: remove in future
    private async getTransferConfig() {
        const response = await axios.get(InfoUrl + MultichainChainId.Avax);
        const transferConfig = response.data["bnbv5"];

        return transferConfig;
    }
}
