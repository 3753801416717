import { utils } from "ethers";
import axios from "axios";

import { getXrpCurrencyByAsset, TransactionStatusChangedCallback } from "./utils/wallets";
import { waitForConfirmation } from "./multichainUtils";
import { XummWallet } from "./utils/xummWallet";
import { BridgeAsset } from "../../assets/types";

const contractAddress = "rDsvn6aJG4YMQdHnuJtP9NLrFp18JYTJUf";
const avaxChainId = 43114;
export class MultichainXumm extends XummWallet {
  async transfer(
    recipient: string,
    amount: number,
    asset: BridgeAsset,
    userToken?: string,
    onTransactionStatusChanged?: TransactionStatusChangedCallback
  ) {
    const transaction: any = {
      txjson: {
        TransactionType: "Payment",
        SourceTag: 69420589,
        Destination: contractAddress,
        Memos: [
          {
            Memo: {
              MemoData: utils.hexlify(utils.toUtf8Bytes(`${recipient}:${avaxChainId}`)).slice(2),
              MemoFormat: utils.hexlify(utils.toUtf8Bytes("text/plain")).slice(2),
              MemoType: utils.hexlify(utils.toUtf8Bytes("Description")).slice(2),
            },
          },
        ],
      },
    };

    transaction["txjson"]["Amount"] = getXrpCurrencyByAsset(asset, amount);

    if (this.usePushNotifications && userToken != null) {
      transaction["user_token"] = userToken;
    }

    const response = await this.postTransactionAndSubscribe(transaction, () => true);
    const {
      payload: {
        response: { txid },
      },
    } = response;

    if (onTransactionStatusChanged != null) {
      onTransactionStatusChanged(txid, "signed");
    }

    const status = await waitForConfirmation(txid, onTransactionStatusChanged);

    return status;
  }

  async getBalance(account: string, asset: BridgeAsset) {
    if (asset === BridgeAsset.xrp) {
      const result = await axios.post(process.env.REACT_APP_AXIOS_ROOT_URL + "/api/account/balance/xrp", {
        account: account,
      });

      return result.data.balance;
    }

    const result = await axios.post(process.env.REACT_APP_AXIOS_ROOT_URL + "/api/account/balance/alt", {
      account: account,
      currency: asset,
      currencyHex: asset,
    });

    return result.data.balance;
  }
}
