import React from "react";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useTheme } from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";
import { Theme } from "@mui/material/styles";

import { useAppState } from "../stores";
import { ReactComponent as StepCompletedIcon } from "../assets/images/stepCompleted.svg";
import { ReactComponent as StepCompletedDarkIcon } from "../assets/images/stepCompletedDark.svg";

const StepIcon = (props: StepIconProps) => {
  const { active, completed, icon } = props;
  const theme = useTheme();

  const iconStyleSx = (theme: Theme) => ({
    width: { xs: "20px", sm: "26px" },
    height: { xs: "20px", sm: "26px" },
    lineHeight: { xs: "18px", sm: "24px" },
    fontSize: { xs: "0.625rem", sm: "0.875rem" },
    borderRadius: "50%",
    border: "1px solid",
    borderColor: active ? theme.palette.text.link : theme.palette.text.gray,
    color: active ? theme.palette.text.link : theme.palette.text.gray,
    "& svg": {
      width: { xs: "20px", sm: "26px" },
      height: { xs: "20px", sm: "26px" },
    },
  });

  const iconCompletedSx = {
    "& svg": {
      width: { xs: "20px", sm: "26px" },
      height: { xs: "20px", sm: "26px" },
    },
  }

  return (
    <>
      {completed ? (
        <Box component="span" sx={iconCompletedSx}>
          {theme.palette.mode !== "dark" ? (
            <StepCompletedIcon width={"26px"} height="26px" />
          ) : (
            <StepCompletedDarkIcon width={"26px"} height="26px" />
          )}
        </Box>
      ) : (
        <Box component="span" sx={iconStyleSx}>
          {icon}
        </Box>
      )}
    </>
  );
};

const stepperSx = {
  "& .MuiStepConnector-root": {
    top: { xs: "10px", sm: "12px" },
    "&.Mui-active, &.Mui-completed": {
      "& .MuiStepConnector-line": {
        borderColor: "background.icon",
      },
    },
  }
};

interface StepsProps { }

const Steps: React.FC<StepsProps> = observer(() => {
  const {
    bridge: { steps, activeStep },
  } = useAppState();

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel sx={stepperSx}>
        {Object.keys(steps).map((key) => (
          <Step key={key}>
            <StepLabel StepIconComponent={StepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
});

export default Steps;
