import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { formatNumber } from "@onxrp/ui-common";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import LoadingButton from "@mui/lab/LoadingButton";
import { Theme, useTheme } from "@mui/material/styles";

import { useAppState } from "@/stores";
import { ReactComponent as CopyIcon } from "@/assets/images/copy.svg";
import { ReactComponent as CopyDarkIcon } from "@/assets/images/copyDark.svg";

import AddressToInput from "./AddressToInput";
import AmountToInput from "./AmountToInput";
import Steps from "./Steps";

const formWrapper = (theme: Theme) => ({
  padding: { xs: "24px", sm: "24px 48px" },
  marginBottom: "24px",
  fontFamily: `"Kallisto", "Helvetica", "Arial", sans-serif`,
  fontWeight: "bold",
  background: "transparent",
  color: theme.palette.button.text,
  border: "1px solid",
  borderColor: "border.main",
  borderRadius: "24px",
});

const warningIconSx = {
  display: "inline-flex",
  width: "18px",
  height: "18px",
  border: "1px solid",
  borderRadius: "24px",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "10px",
};

const warningCardSx = {
  backgroundColor: "background.card",
  borderRadius: "24px",
  padding: "20px",
  textAlign: "left",
};

const feesCardSx = {
  border: "1px solid",
  borderColor: "#fff",
  borderRadius: "24px",
  padding: "20px",
};

const feeListSx = {
  paddingTop: 0,
  "& .MuiTypography-body1": {
    fontSize: "0.625rem",
    fontWeight: 400,
  },
};

const txIconSx = (theme: Theme) => ({
  position: "absolute",
  right: 0,
  top: "2px",
  display: "inline-flex",
  padding: "0 5px",
  cursor: "pointer",
  alignItems: "center",
});

const AddressStepFinished: React.FC<any> = observer(() => {
  const theme = useTheme();
  const {
    bridge: { addressToInputValue, amountToInputValue },
    states: { copyToClipboard },
  } = useAppState();

  const onCopyToClipboard = () => {
    if (!addressToInputValue) return;

    copyToClipboard(addressToInputValue);
  };

  return (
    <>
      <Box sx={formWrapper}>
        <Grid container rowSpacing={3} mt={0}>
          <Grid container mb={1} justifyContent="space-between" alignItems="center">
            <Typography variant="h4" align="left" sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}>
              Amount and Address
            </Typography>
          </Grid>
          <Grid container>
            <Grid container item xs={12} sm={12}>
              <Grid item xs={"auto"}>
                <Typography align="left" mr={1}>
                  To:
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                sm={"auto"}
                pr={1}
                sx={{
                  position: "relative",
                  maxWidth: "80%",
                }}
              >
                <Typography align="left" fontWeight="400" pr={3} noWrap>
                  {addressToInputValue}
                </Typography>
                <Box sx={txIconSx} onClick={onCopyToClipboard}>
                  {theme.palette.mode !== "dark" ? <CopyIcon /> : <CopyDarkIcon />}
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={"auto"}>
                <Typography align="left" mr={1}>
                  Amount:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography align="left" fontWeight="400" noWrap>
                  {amountToInputValue}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

interface AddressStepProps {}

const AddressStep: React.FC<AddressStepProps> = observer(() => {
  const {
    bridge: {
      activeStep,
      sendAmount,
      assetSend,
      assetReceive,
      amountToInputValue,
      addressToInputValue,
      isSendAllowed,
      approveTokensToSpend,
      isAddressHidden,
      balance,
      txOptions,
      isApproveButtonLoading,
      errors: { addressToInputError },
    },
  } = useAppState();

  const fee: {
    amount: number;
    isPercentageAmount: boolean;
    isAmountAllowed?: boolean;
  } = useMemo(() => {
    if (!amountToInputValue || !txOptions) {
      return { amount: 0, isPercentageAmount: false, isAmountAllowed: false };
    }

    let isAmountAllowed: boolean = false;
    let isPercentageAmount: boolean = true;
    let feeAmount = (Number(amountToInputValue) * txOptions.feePercentage) / 100;

    if (
      txOptions.minAmount &&
      txOptions.maxAmount &&
      txOptions.minAmount <= Number(amountToInputValue) &&
      txOptions.maxAmount >= Number(amountToInputValue)
    ) {
      isAmountAllowed = true;
    }

    if (txOptions.feeMinAmount && feeAmount < txOptions.feeMinAmount) {
      feeAmount = txOptions.feeMinAmount;
      isPercentageAmount = false;
    }

    if (txOptions.feeMaxAmount && feeAmount > txOptions.feeMaxAmount) {
      feeAmount = txOptions.feeMaxAmount;
      isPercentageAmount = false;
    }

    return { amount: feeAmount, isPercentageAmount, isAmountAllowed };
  }, [amountToInputValue, txOptions]);

  const amountWithoutFee: number = useMemo(() => {
    if (!amountToInputValue) return 0;

    return Number(amountToInputValue);
  }, [amountToInputValue]);

  const receiveAmount: number = useMemo(() => {
    let amount = amountWithoutFee - fee.amount;

    return amount > 0 ? amount : 0;
  }, [amountWithoutFee, fee]);

  if (activeStep < 1) {
    return (
      <>
        <Box sx={formWrapper}>
          <Grid container mt={0}>
            <Typography variant="h4" align="left" sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}>
              Amount and Address
            </Typography>
          </Grid>
        </Box>
      </>
    );
  }

  if (activeStep > 1) {
    return <AddressStepFinished />;
  }

  return (
    <>
      <Box sx={formWrapper}>
        <Grid container rowSpacing={3} mt={0}>
          <Grid container item justifyContent="center" mb={4}>
            <Grid item xs={8}>
              <Steps />
            </Grid>
          </Grid>
          <Grid container mb={3}>
            <Typography variant="h4" align="left" sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}>
              Amount and Address
            </Typography>
          </Grid>
          {!isAddressHidden && (
            <Grid container mb={2}>
              <Typography align="left" mb={1}>
                Address to Send Your Tokens to
              </Typography>
              <AddressToInput />
            </Grid>
          )}
          <Grid container justifyContent="center" mb={2}>
            <Box sx={warningCardSx}>
              <Grid item xs={12} mb={1}>
                <Typography color="text.warning" component="h5" fontSize="0.875rem">
                  <Box component="span" sx={warningIconSx}>
                    !
                  </Box>{" "}
                  Disclaimer:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.warning" fontSize="0.75rem" fontWeight="400">
                  Refrain from sending tokens to contract or token addresses, and central cryptocurrency exchanges like
                  Binance, Huobi, BitMart, etc.. There is a chance that your tokens will be lost.
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid container mb={5}>
            <Grid container justifyContent={"space-between"} alignItems="baseline">
              <Grid item>
                <Typography align="left" mb={1}>
                  Amount to Send
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="left" mb={1} fontWeight="400" fontSize="0.875rem">
                  Current balance: {formatNumber(balance || 0, 2, 6)} {assetSend?.name}
                </Typography>
              </Grid>
            </Grid>
            <AmountToInput isError={!fee.isAmountAllowed} />
          </Grid>
          <Grid container sx={feesCardSx} mb={4}>
            <Grid container justifyContent={"space-between"} mb={1}>
              <Grid item>
                <Typography fontSize={{ xs: "1rem", sm: "1.25rem" }}>Fees</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} mb={1}>
              <Grid item>
                <Typography fontSize="0.75rem" fontWeight="400">
                  Crosschain Fee {fee?.isPercentageAmount && `(${txOptions?.feePercentage} %)`}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontSize="0.75rem" fontWeight="400">
                  {fee.amount} {assetSend?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} mb={2}>
              <Grid item>
                <Typography fontSize="0.75rem" fontWeight="400">
                  You will receive:
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontSize="0.75rem" fontWeight="600">
                  {receiveAmount} {assetReceive?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} textAlign="left">
                <Typography fontSize={"0.75rem"}>Reminder:</Typography>
              </Grid>
              <Grid item xs={12} textAlign="left" pl={2}>
                <List sx={feeListSx}>
                  <ListItemText>Crosschain Fee is {txOptions?.feePercentage} %</ListItemText>
                  {txOptions?.feeMinAmount && txOptions?.feeMaxAmount && (
                    <ListItemText>
                      Minimum Crosschain Fee is {txOptions?.feeMinAmount} {assetSend?.name}, Maximum Crosschain Fee is{" "}
                      {txOptions?.feeMaxAmount} {assetSend?.name}
                    </ListItemText>
                  )}
                  {txOptions?.minAmount && (
                    <ListItemText>
                      Minimum Crosschain Amount is {txOptions?.minAmount} {assetSend?.name}
                    </ListItemText>
                  )}
                  {txOptions?.maxAmount && (
                    <ListItemText>
                      Maximum Crosschain Amount is {formatNumber(txOptions?.maxAmount, 0, 0)} {assetSend?.name}
                    </ListItemText>
                  )}
                  {txOptions?.estimatedTime && (
                    <ListItemText>
                      Transactions will usually take up to {txOptions?.estimatedTime} min, but in some cases
                      transactions can take hours
                    </ListItemText>
                  )}
                  {txOptions?.bigAmount && (
                    <ListItemText>
                      Crosschain amount larger than {formatNumber(txOptions?.bigAmount, 0, 0)} {assetSend?.name} could
                      take up to 12 hours
                    </ListItemText>
                  )}
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={5}>
              <LoadingButton
                color="secondary"
                variant="onxrpPrimary"
                size="large"
                fullWidth
                disabled={isSendAllowed}
                onClick={() => approveTokensToSpend()}
                loading={isApproveButtonLoading}
              >
                Approval
              </LoadingButton>
            </Grid>
            <Grid item xs={5}>
              <LoadingButton
                color="secondary"
                variant="onxrpPrimary"
                size="large"
                fullWidth
                disabled={!(isSendAllowed && fee.isAmountAllowed && addressToInputValue && !addressToInputError)}
                onClick={() => sendAmount(amountWithoutFee)}
              >
                Send
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

export default AddressStep;
