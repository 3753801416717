import { SupportedTokenSource } from "../stores/bridgeHelpers/utils/wallets";

export interface IChainData {
  key: BridgeChain;
  name: string;
  coin?: string;
  img?: string;
  wallets: BridgeWallet[];
  assets: Partial<
    Record<
      BridgeChain,
      Required<{
        allowedSendAssets: Partial<
          Record<
            BridgeAsset,
            {
              allowedReceiveAsset: Partial<
                Record<
                  BridgeAsset,
                  {
                    receivedAssetKey: BridgeAsset;
                  }
                >
              >;
              isDisable?: boolean;
            }
          >
        >;
      }>
    >
  >;
  active?: boolean;
  hide?: boolean;
  chainData?: EVMChainData;
  blockchainId: SupportedTokenSource;
  txScanBaseUrl?: string;
  multichain?: {
    id: string | number;
  };
}

export enum BridgeChain {
  ethereum,
  xrpLedger,
  bnbChain,
  avax,
}

export type EVMChainData = {
  id: string;
  chainParams?: {
    chainId: string;
    chainName: string;
    rpcUrls: string[];
  };
};

export type txOptionsType = {
  feePercentage: number;
  feeMinAmount?: number;
  feeMaxAmount?: number;
  minAmount?: number;
  maxAmount?: number;
  estimatedTime?: string;
  bigAmount?: number;
};

export interface IAssetData {
  key: BridgeAsset;
  name: string;
  img?: string;
  active?: boolean;
  disabled?: boolean;
  transferConfig?: Partial<Record<BridgeChain, string>>;
  decimals?: number;
  xrplDecimals?: number;
  multichain?: {
    address: string;
  };
}

export enum BridgeAsset {
  eth = "ETH",
  xrp = "XRP",
  usdc = "USDC",
  usdt = "USDT",
  wxrp = "WXRP",
  oxp = "OXP",
  ovx = "OVX",
  xvr = "XVR",
  nio = "NIO",
  pops = "POPS",
  avaxOxp = "AVAXOXP",
  avaxXrp = "AVAXXRP",
  avaxEth = "AVAXETH",
  avaxOvx = "AVAXOVX",
  avaxPops = "AVAXPOPS",
  avaxXvr = "AVAXXVR",
  avaxNio = "AVAXNIO",
}

export interface IWallet {
  key: BridgeWallet;
  name: string;
  img?: string;
  active?: boolean;
}

export enum BridgeWallet {
  metaMask,
  xumm,
  walletConnect,
}
