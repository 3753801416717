import { utils } from "ethers";
import baseX from "base-x";
import { BridgeAsset, BridgeWallet, IAssetData, IChainData } from "../../../assets/types";
import assetsData from "@/assets/assetsData";

export const allBridgeSignEndpoint = "https://allbridgeapi.net/sign/";
export const allBridgeUnlockEndpoint = "https://xrpl.allbridgeapi.net/unlock";
export const allBridgeBalanceEndpoint = "https://xrpl.allbridgeapi.net/balance/";

export const anySwapAPIVersion = "https://bridgeapi.anyswap.exchange/token/version";
export const anySwapAPI = "https://bridgeapi.anyswap.exchange/";

const R_B58_DICT = "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";

export const formatAddressTo32Hex = (address: string, isEthereum: boolean = false) => {
  let formattedAddress;
  if (utils.isAddress(address)) {
    formattedAddress = address;
  } else {
    const res = baseX(R_B58_DICT).decode(address);
    formattedAddress = utils.hexlify(res);
  }
  if (formattedAddress.length < 66) {
    const zerosCount = 66 - formattedAddress.length;
    for (let i = 0; i < zerosCount; i++) {
      formattedAddress += "0";
    }
  }
  if (isEthereum) {
    return formattedAddress;
  }
  return formattedAddress.slice(2).toUpperCase();
};

export type SupportedTokenSource = "ETH" | "XRPL" | "BSC" | "AVX"; // AVX - temp

export type LockFundsResult = {
  lockId: string;
  recipient: string;
  amount: string;
  source: SupportedTokenSource;
  tokenSource: SupportedTokenSource;
  tokenSourceAddress: string;
  signature: string;
};

export type TransactionStatus = "signed" | "source-confirmed" | "dest-confirmed";

export type TransactionStatusChangedCallback = (txid: string, status?: TransactionStatus) => void;

export const getTokenAddress = (sendAsset: IAssetData, from: IChainData) => {
  if (sendAsset.transferConfig != null) {
    const contractAddress = sendAsset.transferConfig[from.key];

    if (contractAddress != null) {
      return contractAddress;
    }
  }

  throw new Error("There is no contract address for this chain!");
};

export const isEthWallet = (wallet: BridgeWallet) =>
  wallet === BridgeWallet.metaMask || wallet === BridgeWallet.walletConnect;

export const isXrpWallet = (wallet: BridgeWallet) => wallet === BridgeWallet.xumm;

export const getXrpCurrencyByAsset = (asset: BridgeAsset, amount: number) => {
  if (asset != BridgeAsset.xrp) {
    const maxDecimals = getAssetDataByAssetKey(asset)?.xrplDecimals;
    amount = truncateNumber(amount, maxDecimals || 6);
  }

  if (asset === BridgeAsset.oxp) {
    return {
      currency: "OXP",
      value: amount,
      issuer: "rrno7Nj4RkFJLzC4nRaZiLF5aHwcTVon3d",
    };
  }

  if (asset === BridgeAsset.ovx) {
    return {
      currency: "OVX",
      value: amount,
      issuer: "rDsvn6aJG4YMQdHnuJtP9NLrFp18JYTJUf",
    };
  }

  if (asset === BridgeAsset.pops) {
    return {
      currency: "POPS",
      value: amount,
      issuer: "rDsvn6aJG4YMQdHnuJtP9NLrFp18JYTJUf",
    };
  }

  if (asset === BridgeAsset.xvr) {
    return {
      currency: "XVR",
      value: amount,
      issuer: "rRCA6gnPkvpKmWwfcYxHQ845gFQ5ThYQe",
    };
  }

  if (asset === BridgeAsset.nio) {
    return {
      currency: "NIO",
      value: amount,
      issuer: "rawT5z6bFKigVRHqu29TdZ6aBejvMEqxxT",
    };
  }

  if (asset === BridgeAsset.xrp) {
    return (amount * 1000000).toString();
  }
};

export const getAssetDataByAssetKey = (asset: BridgeAsset) => {
  return assetsData.find((ad) => ad.key === asset);
};

export const truncateNumber = (value: number, decimals: number) => {
  const divider = Math.pow(10, decimals);
  return Math.trunc(value * divider) / divider;
};
