import { red } from "@mui/material/colors";
import {
  createTheme,
  Theme,
  experimental_sx as sx,
} from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
  interface ButtonPaletteColor {
    main: string;
    contrastText: string;
    gradientStart?: string;
    gradientMiddle?: string;
    gradientEnd?: string;
    gradientStartDisabled?: string;
    gradientEndDisabled?: string;
    text?: string;
    textHover?: string;
    border?: string;
  }
  interface TypeText {
    primary: string;
    gray?: string;
    button?: string;
    link?: string;
    linkFooter?: string;
    xpunk?: string;
    warning?: string;
    error?: string;
    modal?: string;
  }
  interface TypeBackground {
    main: string;
    secondary?: string;
    light: string;
    white: string;
    dark: string;
    body: string;
    card?: string;
    chart?: string;
    input: string;
    tableHead: string;
    gradientStart: string;
    gradientMiddle: string;
    gradientEnd: string;
    icon: string;
    gradientModalStart: string;
    gradientModalEnd: string;
    modalBody: string;
    modalBodySecondary: string;
    backDrop: string;
    menuOverlay: string;
    button: string;
  }
  interface BorderPaletteColor {
    main: string;
    footer?: string;
    light?: string;
    blueOpacity?: string;
  }
  interface Palette {
    button: ButtonPaletteColor;
    border: BorderPaletteColor;
    background: TypeBackground;
    black: {
      main: string;
    };
    white: {
      main: string;
    };
    gray: {
      main: string;
      light: string;
      dark: string;
      xlight: string;
      darker: string;
    };
    purple: {
      main: string;
    };
    green: {
      main: string;
    };
    red: {
      main: string;
    };
    teal: {
      main: string;
    };
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    button: ButtonPaletteColor;
    border: BorderPaletteColor;
    black: {
      main: string;
    };
    white: {
      main: string;
    };
    gray: {
      main: string;
      light: string;
      dark: string;
      xlight: string;
      darker: string;
    };
    purple: {
      main: string;
    };
    green: {
      main: string;
    };
    red: {
      main: string;
    };
    teal: {
      main: string;
    };
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    button: true;
    text: true;
  }
  interface ButtonPropsVariantOverrides {
    onxrpPrimary: true;
    onxrpSecondary: true;
  }
}

const getTheme = (mode: "dark" | "light") => {
  const isDarkMode = mode === "dark";
  // A custom theme for this app
  let theme: Theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 767,
        md: 820,
        lg: 1180,
        xl: 1280,
        xxl: 1440,
      },
    },
    typography: {
      // Tell Material-UI what's the font-size on the html element is.
      htmlFontSize: 20,
    },
    palette: {
      mode: isDarkMode ? "dark" : "light",
      primary: {
        main: isDarkMode ? "#a100bb" : "#0301f7",
        light: "#0301f7",
        dark: "#a100bb",
      },
      secondary: {
        main: isDarkMode ? "#a100bb" : "#8126af",
        light: "#61dafb",
        dark: "#21a1c4",
      },
      gray: {
        main: isDarkMode ? "#eee" : "#848484",
        light: "#b1b1b1",
        dark: "#383434",
        xlight: isDarkMode ? "#e0e0e0" : "#c9c9c9",
        darker: isDarkMode ? "#e0e0e0" : "#161632",
      },
      black: {
        main: isDarkMode ? "#eee" : "#393939",
      },
      white: {
        main: "#fff",
      },
      green: {
        main: "#62b547",
      },
      red: {
        main: "#eb344f",
      },
      purple: {
        main: "#a100b9",
      },
      teal: {
        main: "rgba(255,0,0)",
      },
      error: {
        main: red.A400,
      },
      background: {
        main: isDarkMode ? "#121212" : "transparent",
        secondary: isDarkMode ? "#e0e0e0" : "#eee",
        light: "#fff",
        white: "#fff",
        dark: "#121212",
        body: isDarkMode
          ? "url(/assets/images/bg.png) #121212"
          : "url(/assets/images/bg.png) #eee",
        card: isDarkMode ? "#2b2b2b" : "#fff",
        chart: isDarkMode ? "#121212" : "#f6f6f6",
        input: isDarkMode ? "#262626" : "#f6f6f6",
        tableHead: isDarkMode ? "#e0e0e0" : "#0301f7",
        gradientStart: isDarkMode ? "#f2f2f2" : "#0301f7",
        gradientMiddle: isDarkMode ? "#eee" : "#4e14cf",
        gradientEnd: isDarkMode ? "#eee" : "#8126af",
        icon: isDarkMode ? "#e0e0e0" : "#0301f7",
        gradientModalStart: isDarkMode ? "#e0e0e0" : "#4e48f2",
        gradientModalEnd: isDarkMode ? "#e0e0e0" : "#ae5ecc",
        modalBody: isDarkMode ? "#e0e0e0" : "#d6d6ef",
        modalBodySecondary: isDarkMode ? "#eee" : "#e1e1ee",
        backDrop: isDarkMode ? "#aaa" : "#eee",
        menuOverlay: isDarkMode ? "#aaa" : "rgba(0,0,0,.39)",
        button: isDarkMode ? "#121212" : "#eee",
      },
      text: {
        primary: isDarkMode ? "#e0e0e0" : "#171732",
        secondary: isDarkMode ? "#171732" : "#eee",
        disabled: "#fff",
        button: isDarkMode ? "#171732" : "#f5f5f2",
        gray: isDarkMode ? "#e0e0e0" : "#807e8e",
        link: isDarkMode ? "#e0e0e0" : "#0301f7",
        linkFooter: isDarkMode ? "#e0e0e0" : "#171732",
        xpunk: isDarkMode ? "#e0e0e0" : "#0401f6",
        warning: isDarkMode ? "#e0e0e0" : "#0201ed",
        error: isDarkMode ? "#e0e0e0" : "#aa3b1a",
        modal: isDarkMode ? "#171732" : "#171732",
      },
      button: {
        main: isDarkMode ? "#f5f5f2" : "#0301f7",
        contrastText: isDarkMode ? "#171732" : "#f5f5f2",
        gradientStart: isDarkMode ? "#eee" : "#0301f7",
        gradientMiddle: isDarkMode ? "#eee" : "#4e14cf",
        gradientEnd: isDarkMode ? "#eee" : "#8126af",
        gradientStartDisabled: isDarkMode ? "#f2f2f2" : "#2F4289",
        gradientEndDisabled: isDarkMode ? "#eee" : "#792C7D",
        text: isDarkMode ? "#e0e0e0" : "#171732",
        textHover: isDarkMode ? "#171732" : "#eee",
        border: isDarkMode ? "#eee" : "#0301f7",
      },
      border: {
        main: isDarkMode ? "#e0e0e0" : "#807e8e",
        footer: isDarkMode ? "#e0e0e0" : "rgba(22,22,50,.5)",
        light: isDarkMode ? "#e0e0e0" : "#fff",
        blueOpacity: isDarkMode ? "#e0e0e0" : "rgba(4,1,246,0.08)",
      },
    },
  });

  theme = createTheme(theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontFamily: `"Kallisto", "Helvetica", "Arial", sans-serif`,
            fontSize: "16px",
            background: theme.palette.background.body,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            counterReset: "phase-counter",
          },
          header: {
            "& .MuiTypography-body1": {
              fontSize: "0.75rem",
              [theme.breakpoints.up("xl")]: {
                fontSize: "0.875rem",
              },
            },
            "& .MuiButtonBase-root": {
              fontSize: "0.75rem",
              letterSpacing: "1px",
              padding: "11px 32px",
              borderRadius: "24px",
              boxShadow: `2px 1000px 1px ${
                isDarkMode ? "#121212" : "#e2e1ef"
              } inset`,
              [theme.breakpoints.up("lg")]: {
                fontSize: "0.875rem",
                letterSpacing: "2px",
              },
              [theme.breakpoints.down("md")]: {
                fontSize: "0.5rem",
                minWidth: "50%",
                marginBottom: 2,
                padding: "7px 21px",
              },
              "&.theme-mode-btn": {
                background: isDarkMode ? "#EBEBEB" : "#000000",
                color: isDarkMode ? "#1B1B2F" : "#FFFFFF",
                boxShadow: "none",
                "&:hover": {
                  background: isDarkMode ? "#EBEBEB" : "#000000",
                  color: isDarkMode ? "#1B1B2F" : "#FFFFFF",
                  boxShadow: "none",
                },
              },
            },
          },
          "& .boxCard": {
            borderStyle: "solid",
            borderColor: theme.palette.border.main,
            borderWidth: "1px",
            borderRadius: "24px",
            [theme.breakpoints.down("sm")]: {
              borderWidth: "1px",
            },
          },
          img: {
            maxWidth: "100%",
          },
          "& .modal-body": {
            "& .MuiInput-root": {
              backgroundColor: isDarkMode ? "#fff" : "#f6f6f6",
              color: "#171732",
            },
            "& .MuiTypography-root": {
              color: isDarkMode ? "#171732" : "#393939",
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: theme.palette.black.main,
            fontFamily: `"Kallisto", "Helvetica", "Arial", sans-serif`,
            fontWeight: "bold",
            "&.xpunks-logo": {
              span: {
                "&:last-child": {
                  color: theme.palette.text.primary + "!important",
                },
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "7px 7px 7px 1px rgb(0 0 0 / 15%)",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: sx({
            boxShadow: "none",
            padding: { xs: "21px 0", md: "32px 0", lg: "39px 0" },
            backgroundImage: "none",
            backgroundColor: "transparent",
            "& .MuiToolbar-root": {
              minHeight: { xs: 0, md: "48px" },
            },
          }),
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: sx({
            maxWidth: {
              xs: "calc(100% - 48px)",
              sm: "calc(100% - 96px)",
              md: "calc(100% - 112px)",
              lg: "calc(100% - 100px)",
              xl: "calc(100% - 160px)",
              xxl: "1280px",
            },
            "&.page": {
              padding: 0,
            },
          }),
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            "& th": {
              backgroundColor: "transparent",
              color: theme.palette.white.main,
              fontWeight: "bold",
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: theme.palette.text.primary,
            fontFamily: `"Kallisto", "Helvetica", "Arial", sans-serif`,
            fontWeight: 600,
            position: "unset",
            padding: "6px 10px",
            wordBreak: "break-word",
            [theme.breakpoints.down("md")]: {
              padding: "8px",
            },
            "&.table-cell-sticky": {
              position: "sticky",
              left: 0,
              background: theme.palette.background.main,
              "&.table-cell-sticky-header": {
                background: "transparent",
                [theme.breakpoints.down("md")]: {
                  paddingLeft: "24px",
                  backgroundImage: isDarkMode
                    ? `linear-gradient(90deg, ${theme.palette.background.gradientStart}, ${theme.palette.background.gradientEnd})`
                    : `linear-gradient(90deg, ${theme.palette.background.gradientStart}, #4d14ce)`,
                },
              },
            },
            "&.table-cell-sticky-header": {
              color: theme.palette.text.secondary,
              position: "sticky",
              minWidth: "65px",
            },
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "onxrpPrimary" },
            style: {
              position: "relative",
              padding: "9px 24px",
              textTransform: "uppercase",
              color: theme.palette.button.textHover,
              boxShadow: "none",
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, ${theme.palette.button.gradientStart}, ${theme.palette.button.gradientEnd})`,
              border: "1px solid",
              borderColor: "transparent",
              borderRadius: "24px",
              overflow: "hidden",
              transitionDuration: "0ms",
              "&:hover": {
                color: theme.palette.button.text,
                background: "transparent",
                borderColor: theme.palette.button.border,
              },
              "&.Mui-disabled": {
                color: "#aaaaaa",
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, ${theme.palette.button.gradientStartDisabled}, ${theme.palette.button.gradientEndDisabled})`,
                "&:hover": {
                  color: "#aaaaaa",
                  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, ${theme.palette.button.gradientStartDisabled}, ${theme.palette.button.gradientEndDisabled})`,
                },
              },
              "& .MuiLoadingButton-loadingIndicator": {
                color: theme.palette.button.textHover,
              },
            },
          },
          {
            props: { variant: "onxrpSecondary" },
            style: {
              position: "relative",
              padding: "9px 24px",
              textTransform: "uppercase",
              boxShadow: "none",
              color: theme.palette.button.text,
              background: "transparent",
              border: "1px solid",
              borderColor: theme.palette.button.border,
              borderRadius: "24px",
              overflow: "hidden",
              transitionDuration: "0ms",
              "&:hover": {
                color: theme.palette.button.textHover,
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, ${theme.palette.button.gradientStart}, ${theme.palette.button.gradientEnd})`,
                borderColor: "transparent",
              },
              "&.Mui-disabled": {
                borderColor: "transparent",
                color: "#aaaaaa",
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, ${theme.palette.button.gradientStartDisabled}, ${theme.palette.button.gradientEndDisabled})`,
                "&:hover": {
                  color: "#aaaaaa",
                  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, ${theme.palette.button.gradientStartDisabled}, ${theme.palette.button.gradientEndDisabled})`,
                },
              },
              "& .MuiLoadingButton-loadingIndicator": {
                color: theme.palette.button.textHover,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            fontFamily: `"Kallisto", "Helvetica", "Arial", sans-serif`,
            fontWeight: "bold",
            borderRadius: "20px",
            background: "none",
            color: theme.palette.button.text,
            border: "1px solid transparent",
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, ${theme.palette.button.gradientStart}, ${theme.palette.button.gradientEnd})`,
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            boxShadow: `2px 1000px 1px ${theme.palette.background.button} inset`,
            "&:hover": {
              boxShadow: "none",
              color: theme.palette.button.textHover,
            },
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.26)",
            },
          },
          containedSecondary: {
            boxShadow: "none",
            color: theme.palette.button.textHover,
            "&:hover": {
              color: theme.palette.button.text,
              boxShadow: `1px 1000px 1px ${theme.palette.background.button} inset`,
            },
          },
          text: {
            border: "none",
            background: "none",
            padding: 0,
            color: theme.palette.text.link,
            boxShadow: "none",
            "&:hover": {
              background: "none",
              color: theme.palette.text.link,
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            "&.tabs-content button": {
              backgroundColor: theme.palette.secondary.main,
              borderBottomRightRadius: "0",
              borderBottomLeftRadius: "0",
              lineHeight: "4",
              zIndex: 1,
            },
            "&.tabs-content .Mui-selected": {
              backgroundColor: "transparent",
              color: theme.palette.black.main,
              zIndex: 100,
            },
            "&.chart-period-buttons button": {
              backgroundColor: "transparent",
              color: theme.palette.text.secondary,
              "&:hover": {
                backgroundColor: theme.palette.background.main,
                color: theme.palette.text.primary,
              },
              [theme.breakpoints.down("sm")]: {
                padding: "8px",
                fontSize: "0.5rem",
              },
            },
            "&.chart-period-buttons .Mui-selected": {
              backgroundColor: isDarkMode ? "#252626" : "#ededed",
              color: theme.palette.black.main,
            },
            "&.market-mode button": {
              backgroundColor: "transparent",
              color: theme.palette.text.secondary,
              "&:hover": {
                backgroundColor: theme.palette.background.button,
                color: theme.palette.text.primary,
              },
            },
            "&.market-mode .Mui-selected": {
              backgroundColor: isDarkMode ? "#121212" : "#e7dfec",
              color: theme.palette.text.primary,
            },
            "&.amount-buy-buttons button": {
              backgroundColor: isDarkMode ? "#121212" : "#e7dfec",
              color: theme.palette.text.primary,
              "&:hover": {
                backgroundColor: "transparent",
                color: theme.palette.text.secondary,
              },
            },
            "&.coin-trade-tabs button": {
              backgroundColor: "transparent",
              fontWeight: 600,
              ".MuiTypography-root": {
                color: theme.palette.text.secondary,
              },
              img: {
                filter: isDarkMode ? "invert(1)" : "invert(0)",
              },
              "&:hover, &.Mui-selected": {
                backgroundColor: isDarkMode ? "#121212" : "#e9dfeb",
                [theme.breakpoints.down("sm")]: {
                  backgroundColor: isDarkMode ? "#121212" : "#efefef",
                },
                ".MuiTypography-root": {
                  color: theme.palette.text.primary,
                },
                img: {
                  filter: isDarkMode ? "invert(0)" : "invert(1)",
                },
              },
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            border: "0",
            color: theme.palette.white.main,
            backgroundColor: theme.palette.primary.main,
            fontFamily: `"Kallisto", "Helvetica", "Arial", sans-serif`,
            fontWeight: "bold",
            "&.Mui-selected": {
              color: theme.palette.white.main,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.background.main,
              },
            },
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.input,
            color: theme.palette.black.main,
            borderRadius: "24px !important",
            padding: "5px 25px 5px 10px",
            fontFamily: `"Kallisto", "Helvetica", "Arial", sans-serif`,
            fontSize: "1.2rem",
            fontWeight: "bold",
            border: "0",
            "& input": {
              padding: "14px 0px 14px 14px",
              fontSize: "0.7rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "& .Mui-disabled": {
              WebkitTextFillColor: `${theme.palette.black.main} !important`,
            },
            "&.coin-pairs": {
              backgroundColor: "transparent",
              "&:before": {
                border: "0 !important",
              },
              "& .MuiSelect-select": {
                paddingBottom: 0,
              },
              "& .MuiListItemText-root": {
                margin: 0,
              },
              "& .MuiTypography-root": {
                color: "white",
                fontSize: "1.2rem",
              },
              "& .MuiSvgIcon-root": {
                color: theme.palette.primary.main,
              },
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            "& .MuiTypography-root": {
              color: theme.palette.gray.main,
              fontSize: "0.6rem",
              marginRight: "10px",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: theme.palette.white.main,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            "& .MuiBackdrop-root": {
              backgroundColor: theme.palette.background.backDrop,
              opacity: "0.9!important",
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            "&.pairs-search": {
              ".MuiInputBase-input": {
                borderRadius: "24px !important",
                padding: "5px",
                fontSize: "0.875rem",
                fontWeight: 400,
                paddingLeft: "12px",
              },
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: theme.palette.background.icon,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: theme.palette.text.link,
            textDecoration: "none",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            margin: 0,
            "&::-webkit-scrollbar-thumb": {
              background: theme.palette.text.modal,
            },
            "&::-webkit-scrollbar": {
              background: "transparent",
              width: "4px",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: "15px 24px",
          },
        },
      },
    },
  });

  return theme;
};

export default getTheme;
