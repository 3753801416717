import React from "react";
import { observer } from "mobx-react";
import { Box, Modal, Backdrop, Fade, Grid, Typography } from "@mui/material";

import { useAppState } from "../stores";

import { ReactComponent as WalletIcon } from "../assets/images/wallet.svg";
import { ReactComponent as CloseIcon } from "../assets/images/close.svg";

const modalBodyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 40px)",
  maxWidth: "500px",
  bgcolor: "background.modalBody",
  borderRadius: "24px",
  padding: "16px 32px 32px",
};

interface WaitWalletProps { }

const WaitWallet: React.FC<WaitWalletProps> = observer(() => {
  const {
    bridge: { isWaitingWallet, closeWaitingWallet },
  } = useAppState();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal-body"
        open={isWaitingWallet}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: {
            opacity: "0.9",
          },
          sx: {
            backgroundColor: "background.backDrop",
          },
        }}
        disableAutoFocus
      >
        <Fade in={isWaitingWallet}>
          <Box sx={modalBodyStyle}>
            <Grid container justifyContent="center" textAlign="center">
              <Grid item xs={12} textAlign="right">
                <CloseIcon
                  onClick={closeWaitingWallet}
                  height={16}
                  width={16}
                  cursor="pointer"
                />
              </Grid>
              <Grid item xs={12}>
                <WalletIcon />
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize="1.5rem">Connecting...</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Please, confirm action on your wallet</Typography>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
});

export default WaitWallet;
