import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { IAssetData } from "../assets/types";

interface SelectAssetsOptionProps {
  asset: IAssetData;
}

const SelectAssetsOption: React.FC<SelectAssetsOptionProps> = ({ asset }) => {

  return (
    <>
      <Grid container alignContent="center">
        <Grid
          item
          mr={2}
          alignSelf="center"
          sx={{
            "& img": {
              display: "flex",
              maxWidth: "25px",
              maxHeight: "25px",
              width: "100%",
              height: "100%",
            },
          }}
        >
          {asset?.img ? (
            <img
              src={"/assets/images/coin-icons/" + asset.img}
              alt={asset.name}
            />
          ) : (
            <img
              src={"/assets/images/icons/unknown-icon.svg"}
              alt={asset.name}
            />
          )}
        </Grid>
        <Grid item>
          <Typography>
            {asset.name}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectAssetsOption;
