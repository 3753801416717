import React from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { Theme } from "@mui/material/styles";

import Steps from "./Steps";

import { useAppState } from "../stores";
import { ReactComponent as OneMoreStepIcon } from "../assets/images/oneMoreStep.svg";
import { ReactComponent as OneMoreStepDarkIcon } from "../assets/images/oneMoreStepDark.svg";
import { ReactComponent as CopyIcon } from "../assets/images/copy.svg";
import { ReactComponent as CopyDarkIcon } from "../assets/images/copyDark.svg";
import { ReactComponent as OpenLinkIcon } from "../assets/images/openLink.svg";
import { ReactComponent as OpenLinkDarkIcon } from "../assets/images/openLinkDark.svg";
import { ReactComponent as GearIcon } from "../assets/images/gearLight.svg";
import { ReactComponent as GearDarkIcon } from "../assets/images/gearDark.svg";

const formWrapper = (theme: Theme) => ({
  padding: { xs: "24px", sm: "24px 48px" },
  marginBottom: "24px",
  fontFamily: `"Kallisto", "Helvetica", "Arial", sans-serif`,
  fontWeight: "bold",
  background: "transparent",
  color: theme.palette.button.text,
  border: "1px solid",
  borderColor: "border.main",
  borderRadius: "24px",
});

const txSx = (theme: Theme) => ({
  border: "1px solid",
  borderColor: theme.palette.border.light,
  borderRadius: "24px",
  padding: "5px 20px",
  align: "center",
  marginBottom: "8px",
});

const txIconSx = (theme: Theme) => ({
  display: "inline-flex",
  padding: "0 5px",
  cursor: "pointer",
  alignItems: "center",
});

const txIdSx = {
  display: "inline-block",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "200px",
  whiteSpace: "nowrap",
};

const txIdCmSX = {
  ...txIdSx,
  maxWidth: "360px",
};

const iconWrapperSx = (theme: Theme) => ({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100px",
  height: "100px",
  // border: "1px solid",
  // borderRadius: "50%",
  color: theme.palette.background.icon,
  borderColor: theme.palette.background.icon,
});

const ConfirmationStepFinished: React.FC<any> = observer(() => {
  const {
    bridge: { chainFrom, sourceTransactionId },
    states: { copyToClipboard },
  } = useAppState();
  const theme = useTheme();

  const onCopyToClipboard = () => {
    if (!sourceTransactionId) return;
    copyToClipboard(sourceTransactionId);
  };

  return (
    <>
      <Box sx={formWrapper}>
        <Grid container rowSpacing={3} mt={0}>
          <Grid
            container
            mb={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h4"
              align="left"
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              Confirmation
            </Typography>
          </Grid>
          <Grid container>
            <Grid container item xs={"auto"}>
              <Typography align="left" mr={1}>
                Tx ID:
              </Typography>
              <Typography
                align="left"
                fontWeight="400"
                sx={txIdCmSX}
                maxWidth="360px"
              >
                {sourceTransactionId}
              </Typography>
              <Box sx={txIconSx} onClick={onCopyToClipboard}>
                {theme.palette.mode !== "dark" ? (
                  <CopyIcon />
                ) : (
                  <CopyDarkIcon />
                )}
              </Box>
              {chainFrom?.txScanBaseUrl && sourceTransactionId && (
                <Box sx={txIconSx}>
                  <Box
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    href={chainFrom?.txScanBaseUrl + sourceTransactionId}
                  >
                    {theme.palette.mode !== "dark" ? (
                      <OpenLinkIcon />
                    ) : (
                      <OpenLinkDarkIcon />
                    )}
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

interface ConfirmationStepProps { }

const ConfirmationStep: React.FC<ConfirmationStepProps> = observer(() => {
  const {
    bridge: {
      isSendTxConfirmed,
      activeStep,
      sourceTransactionId,
      destTransactionId,
      chainFrom,
      chainTo,
      bridgeAgain
    },
    states: { copyToClipboard },
  } = useAppState();
  const theme = useTheme();

  const onCopyToClipboard = (txid: string) => {
    if (!txid) return;
    copyToClipboard(txid);
  };

  if (activeStep < 2) {
    return (
      <>
        <Box sx={formWrapper}>
          <Grid container rowSpacing={3} mt={0}>
            <Grid container>
              <Typography
                variant="h4"
                align="left"
                sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
              >
                Confirmation
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }

  if (activeStep > 2) {
    return <ConfirmationStepFinished />;
  }

  return (
    <>
      <Box sx={formWrapper}>
        <Grid container rowSpacing={3} mt={0}>
          <Grid container justifyContent="center" mb={4}>
            <Grid item xs={8}>
              <Steps />
            </Grid>
          </Grid>
          <Grid container mb={3}>
            <Typography
              variant="h4"
              align="left"
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              Confirmation
            </Typography>
          </Grid>
          <Grid container justifyContent="center" mb={2}>
            <Grid item xs={12}>
              <Box sx={iconWrapperSx} mb={2}>
                {!isSendTxConfirmed ? (
                  <Box className="rotating" sx={{ display: "flex" }} >
                    {theme.palette.mode !== "dark" ? <GearIcon /> : <GearDarkIcon />}
                  </Box>
                ) : theme.palette.mode !== "dark" ? (
                  <OneMoreStepIcon />
                ) : (
                  <OneMoreStepDarkIcon />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="h5"
                fontSize={{ xs: "1.25rem", sm: "1.5rem" }}
                mb={1}
              >
                {!isSendTxConfirmed
                  ? "Transaction is pending"
                  : "Transactions are confirmed!"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="0.875rem" fontWeight="400">
                {!isSendTxConfirmed && "Transaction can take some time if the network is overloaded"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container mb={3} justifyContent="center">
            {sourceTransactionId != null &&
              <Box sx={txSx}>
                <Grid container item xs={12} justifyContent="center">
                  <Grid item xs={12} sm={"auto"} lineHeight={1} alignSelf="center">
                    <Typography
                      fontSize="0.75rem"
                      fontWeight="700"
                      display="inline"
                      mr={1}
                      textAlign="left"
                    >
                      Source chain Tx ID:
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm alignItems="center" justifyContent={"center"}>
                    <Typography
                      fontSize="0.75rem"
                      fontWeight="400"
                      display="inline"
                      color="text.link"
                      sx={txIdSx}
                      noWrap
                    >
                      {sourceTransactionId}
                    </Typography>
                    <Box sx={txIconSx} onClick={() => onCopyToClipboard(sourceTransactionId)}>
                      {theme.palette.mode !== "dark" ? (
                        <CopyIcon />
                      ) : (
                        <CopyDarkIcon />
                      )}
                    </Box>
                    {chainFrom?.txScanBaseUrl && (
                      <Box sx={txIconSx}>
                        <Box
                          component="a"
                          target="_blank"
                          rel="noreferrer"
                          href={chainFrom?.txScanBaseUrl + sourceTransactionId}
                        >
                          {theme.palette.mode !== "dark" ? (
                            <OpenLinkIcon />
                          ) : (
                            <OpenLinkDarkIcon />
                          )}
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            }
            {destTransactionId != null &&
              <Box sx={txSx}>
                <Grid container item xs={12} justifyContent="center">
                  <Grid item xs={12} sm={"auto"} lineHeight={1} alignSelf="center">
                    <Typography
                      fontSize="0.75rem"
                      fontWeight="700"
                      display="inline"
                      mr={1}
                      textAlign="left"
                    >
                      Destination chain Tx ID:
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm alignItems="center" justifyContent={"center"}>
                    <Typography
                      fontSize="0.75rem"
                      fontWeight="400"
                      display="inline"
                      color="text.link"
                      sx={txIdSx}
                    >
                      {destTransactionId}
                    </Typography>
                    <Box sx={txIconSx} onClick={() => onCopyToClipboard(destTransactionId)}>
                      {theme.palette.mode !== "dark" ? (
                        <CopyIcon />
                      ) : (
                        <CopyDarkIcon />
                      )}
                    </Box>
                    {chainTo?.txScanBaseUrl && (
                      <Box sx={txIconSx}>
                        <Box
                          component="a"
                          target="_blank"
                          rel="noreferrer"
                          href={chainTo?.txScanBaseUrl + destTransactionId}
                        >
                          {theme.palette.mode !== "dark" ? (
                            <OpenLinkIcon />
                          ) : (
                            <OpenLinkDarkIcon />
                          )}
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            }
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                color="secondary"
                variant="onxrpPrimary"
                fullWidth
                disabled={!isSendTxConfirmed}
                onClick={() => bridgeAgain()}
              >
                Bridge again
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

export default ConfirmationStep;
