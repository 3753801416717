import React from "react";
import { observer } from "mobx-react";

import { useAppState } from "../stores";
import SelectChainOption from "./SelectChainOption";

interface SelectChainValueProps {
}

const SelectChainFromValue: React.FC<SelectChainValueProps> = observer(() => {
  const { bridge: { chainFrom } } = useAppState();

  return (
    <>
      {chainFrom &&
        <SelectChainOption chain={chainFrom} />
      }
    </>
  );
});

export default SelectChainFromValue;
