import ReactGA from "react-ga4";
import Layout from "./components/Layout";

import "./App.css";

// Just initialization is needed
// https://medium.com/@vergil333/google-analytics-4-in-react-a250005c2455
const GOOGLE_ANALYTICS_ID: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
if (typeof GOOGLE_ANALYTICS_ID === "string") {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
} else {
  ReactGA.initialize("test", { testMode: true });
}

function App() {
  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;
