import React from "react";
import { observer } from "mobx-react";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useAppState } from "../stores";

const inputWrapperSx = {
  position: "relative",
  width: "100%",
};

const inputSx = {
  background: "transparent",
  border: "1px solid #c2c2c8",
  borderRadius: "24px",
  padding: { xs: "10px 10% 10px 10px", sm: "15px 40px 15px 40px" },
  height: { xs: "40px", sm: "48px" },
  "& input": {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 700,
    color: "gray.darker",
    padding: 0,
  },
};

interface AddressToInputProps { }

const AddressToInput: React.FC<AddressToInputProps> = observer(() => {
  const {
    bridge: {
      setAddressToInputValue,
      addressToInputValue,
      errors: { addressToInputError },
    },
  } = useAppState();

  const onChangeInput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setAddressToInputValue(e.target?.value);
  };

  return (
    <>
      <Box sx={inputWrapperSx}>
        <Input
          type="text"
          placeholder="Enter wallet address..."
          disableUnderline
          fullWidth
          sx={inputSx}
          onChange={onChangeInput}
          value={addressToInputValue || ""}
        />
      </Box>
      {addressToInputError && (
        <Box mt={"4px"} pl={"40px"} textAlign="left">
          <Typography
            sx={{
              fontSize: "0.75rem",
              fontWeight: 700,
              lineHeight: "1rem",
              color: "text.error",
            }}
          >
            {addressToInputError}
          </Typography>
        </Box>
      )}
    </>
  );
});

export default AddressToInput;
