import axios from "axios";
import { TransactionStatusChangedCallback } from "./utils/wallets";

const StatusUrl = "https://bridgeapi.anyswap.exchange/v2/history/details/latest?params=";

enum TxStatus {
    SourceConfirmed = 8,
    DestConfirmed = 9,
    DestConfirmedStable = 10,
}

export const waitForConfirmation = async (txhash: string, onTransactionStatusChanged?: TransactionStatusChangedCallback) => {
    const status = await new Promise(resolve => {
        const id = setInterval(async () => {
            const response = await axios.get(StatusUrl + txhash);
            if (response?.data?.msg === "Success") {
                switch (response.data.info.status) {
                    case TxStatus.SourceConfirmed:
                        if (onTransactionStatusChanged != null) {
                            onTransactionStatusChanged(response.data.info.txid, 'source-confirmed');
                        }
                        break;
                    case TxStatus.DestConfirmed:
                    case TxStatus.DestConfirmedStable:
                        if (onTransactionStatusChanged != null) {
                            onTransactionStatusChanged(response.data.info.swaptx, 'dest-confirmed');
                        }
                        clearInterval(id);
                        resolve(response.data);
                        break;
                }
            }
        }, 10000); // 10 sec
    });
    return status as any;
}

// Sample response data from StatusUrl endpoint. TODO: remove it
const a = {
    "msg": "Success",
    "info": {
        "pairid": "OXP",
        "txid": "E2069D0EE42DEB16B689C8E12FD45A5F24233C1D5F8464E39CC781433514A231",
        "txto": "",
        "txheight": "",
        "txtime": "",
        "from": "rhJMLhwEsPPyGhiv9a1AGrdPJy8ymYVPRw",
        "to": "rDsvn6aJG4YMQdHnuJtP9NLrFp18JYTJUf",
        "bind": "0x8b2ef39401c76F9C7eF07545e7fa01BdfeBb8c29",
        "value": "130000000",
        "swaptx": "",
        "swapheight": "",
        "swaptime": "",
        "swapvalue": "0",
        "swaptype": 1,
        "swapnonce": "",
        "status": 8,
        "statusmsg": "MatchTxEmpty",
        "timestamp": 1655277115,
        "memo": "",
        "swapinfo": {
            "routerSwapInfo": {
                "token": "OXP/rrno7Nj4RkFJLzC4nRaZiLF5aHwcTVon3d",
                "tokenID": "OXP"
            }
        },
        "confirmations": "",
        "srcChainID": "XRP",
        "destChainID": "43114",
        "historyType": "NON_EVM",
        "formatswapvalue": "0",
        "formatvalue": "130",
        "formatfee": "",
        "time": "",
        "fromChainID": "XRP",
        "toChainID": "43114",
        "logIndex": "",
        "label": "onxrp",
        "inittime": 1655277115347
    }
};