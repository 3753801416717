import React, { useState, useMemo } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Header, Footer } from "@onxrp/ui-common";
import { ToastContainer } from "react-toastify";

import BridgePage from "../pages/BridgePage";
import DisconnectButton from "../components/DisconnectButton";

import { getTheme, appTheme } from "../themes";

import "react-toastify/dist/ReactToastify.css";

const Layout: React.FC<any> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(() => {
    const modeQuery = searchParams.get("mode");

    if (modeQuery && modeQuery === "dark") {
      return true;
    }
    if (modeQuery && modeQuery === "light") {
      return false;
    }

    const mode = localStorage.getItem("onxrp_isDarkMode");
    const isDarkMode = mode ? JSON.parse(mode) === "dark" : false;

    return isDarkMode;
  });

  const theme = useMemo(() => {
    const mode = isDarkTheme ? "dark" : "light";
    localStorage.setItem("onxrp_isDarkMode", JSON.stringify(mode));

    const _searchParams = searchParams;
    _searchParams.delete("mode");
    setSearchParams(_searchParams);

    const baseTheme = getTheme(mode);
    const theme = createTheme(baseTheme, appTheme);

    return theme;
  }, [isDarkTheme]);

  const onChangeColorTheme = () => {
    setIsDarkTheme((prevMode) => !prevMode);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header onChangeColorTheme={onChangeColorTheme} theme={theme} walletConnectBtn={<DisconnectButton />} />
        <Routes>
          <Route path="/" element={<BridgePage />} />
        </Routes>
        <Footer theme={theme} />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </div>
  );
};

export default Layout;
