import React from "react";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { useAppState } from "../stores";
import { IWallet } from "../assets/types";

import ConnectWallets from "./ConnectWallets";
import SelectWalletOptions from "./SelectWalletOptions";

interface ConnectWalletsProps {
  children?: React.ReactChild | React.ReactChild[];
}

const DisconnectButton: React.FC<ConnectWalletsProps> = observer(() => {
  const {
    bridge: {
      isWalletConnected,
      disconnectWallet,
      setChainFromWallet,
    },
  } = useAppState();

  const onDisconnect = () => {
    disconnectWallet();
  };

  const onSelectWallet = (wallet: IWallet) => {
    setChainFromWallet(wallet);
  };

  return (
    <>
      {isWalletConnected ?
        <Box sx={{ width: "100%" }}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={onDisconnect}
          >
            Disconnect
          </Button>
        </Box>
        : <Grid container>
          <ConnectWallets>
            <SelectWalletOptions handleSelect={onSelectWallet} />
          </ConnectWallets>
        </Grid>}
    </>
  );
});

export default DisconnectButton;
