import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Input,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";

import { ReactComponent as CloseIcon } from "../assets/images/close.svg";
import chainsData from "../assets/chainsData";
import SelectChainOption from "./SelectChainOption";
import { IChainData } from "../assets/types";

interface SelectChainOptionsProps {
  children?: React.ReactChild | React.ReactChild[];
  handleSelect: (chain: IChainData) => void;
  handleClose?: () => void;
}

const SelectChainOptions: React.FC<SelectChainOptionsProps> = ({
  handleSelect,
  handleClose,
}) => {
  const [chains, setChains] = useState<IChainData[]>(chainsData);

  const onSelectChain = (chain: IChainData) => {
    if (!chain.active) return;

    handleSelect(chain);
    if (typeof handleClose === "function") {
      handleClose();
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const searchPhrase = e.target?.value;
    const result = searchPhrase
      ? chainsData.filter((chain) =>
        chain.name.toLowerCase().includes(searchPhrase.toLowerCase())
      )
      : chainsData;

    setChains(result);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="baseline"
          pb={3}
        >
          <Grid item>
            <Typography variant="h3">Select a blockchain</Typography>
          </Grid>
          <Grid item sx={{ display: "flex" }}>
            <CloseIcon
              onClick={handleClose}
              height={16}
              width={16}
              cursor="pointer"
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={2}>
          <Input
            id="blockchain-base-input"
            name="blockchain-base"
            placeholder="Type blockchain name"
            fullWidth
            disableUnderline
            inputProps={{
              style: {
                fontSize: "1rem",
                padding: "14px 20px",
              },
            }}
            onChange={onChange}
          />
        </Grid>
        <Box>
          <List sx={{ maxHeight: "300px" }}>
            {chains
              .filter((item) => !item.hide)
              .map((item) => (
                <ListItem
                  key={item.key}
                  sx={{ p: 0 }}
                  onClick={() => onSelectChain(item)}
                >
                  <ListItemButton
                    disabled={!item.active}
                    sx={(theme) => ({
                      "&:hover": {
                        background:
                          theme.palette.mode !== "dark" ? "#e2e2ed" : "#e5e5e5",
                      },
                    })}
                  >
                    <SelectChainOption chain={item} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      </Box>
    </>
  );
};

export default SelectChainOptions;
