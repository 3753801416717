import React from "react";
import { observer } from "mobx-react";
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useAppState } from "../stores";

const inputWrapperSx = {
	position: "relative",
	width: "100%",
}

const inputSx = {
	background: 'transparent',
	border: '1px solid #c2c2c8',
	borderRadius: 24,
	padding: { xs: '10px 10% 10px 10px', sm: '15px 40px 15px 40px' },
	height: { xs: "40px", sm: "48px" },
	"& input": {
		fontSize: '0.875rem',
		fontStyle: 'normal',
		fontWeight: 700,
		color: "gray.darker",
		padding: 0,
	}
}

interface TransferToInputProps {
}

const TransferToInput: React.FC<TransferToInputProps> = observer(() => {
	const {
		bridge: { setTransferToInputValue, transferToInputValue, errors: { transferToInputError } },
	} = useAppState();

	const onChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setTransferToInputValue(e.target?.value);
	}

	return (
		<>
			<Box sx={inputWrapperSx}>
				<Input
					type="text"
					name="email"
					placeholder="Enter the transaction ID..."
					disableUnderline
					fullWidth
					sx={inputSx}
					onChange={onChangeInput}
					value={transferToInputValue || ""}
				/>
			</Box>
			{transferToInputError &&
				<Box mt={"4px"} pl={"40px"} textAlign="left">
					<Typography sx={{
						fontSize: '0.75rem',
						fontWeight: 700,
						lineHeight: '1rem',
						color: "text.error"
					}}>
						{transferToInputError}
					</Typography>
				</Box>
			}
		</>
	)
});

export default TransferToInput;