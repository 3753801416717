import { BridgeChain, IChainData } from "../../../assets/types"

export enum FlowDirection {
    Send,
    Receive,
    None,
};

type FlowCheckerFn = (from: IChainData, to: IChainData) => FlowDirection;

export const avaxToBnbFlow: FlowCheckerFn = (from: IChainData, to: IChainData) => {
    if (from.key === BridgeChain.avax && to.key === BridgeChain.bnbChain) {
        return FlowDirection.Send;
    }
    if (from.key === BridgeChain.bnbChain && to.key === BridgeChain.avax) {
        return FlowDirection.Receive;
    }
    return FlowDirection.None;
}

export const avaxToXrpFlow: FlowCheckerFn = (from: IChainData, to: IChainData) => {
    if (from.key === BridgeChain.avax && to.key === BridgeChain.xrpLedger) {
        return FlowDirection.Send;
    }
    if (from.key === BridgeChain.xrpLedger && to.key === BridgeChain.avax) {
        return FlowDirection.Receive;
    }
    return FlowDirection.None;
}

export const xrpToBnbFlow: FlowCheckerFn = (from: IChainData, to: IChainData) => {
    if (from.key === BridgeChain.xrpLedger && to.key === BridgeChain.bnbChain) {
        return FlowDirection.Send;
    }
    if (from.key === BridgeChain.bnbChain && to.key === BridgeChain.xrpLedger) {
        return FlowDirection.Receive;
    }
    return FlowDirection.None;
}

export const isSendOrReceiveFlow = (flowChecker: FlowCheckerFn, from: IChainData, to: IChainData) => {
    const checkResult = flowChecker(from, to);
    return checkResult === FlowDirection.Send || checkResult === FlowDirection.Receive;
}
