import { ethers, utils } from "ethers";
import axios from "axios";

import allbridgeAbi from "../../assets/contracts/allbridge-abi.json";

import {
    allBridgeSignEndpoint,
    formatAddressTo32Hex,
    LockFundsResult,
    SupportedTokenSource,
    TransactionStatusChangedCallback
} from "./utils/wallets";
import { Erc20Helper } from "./utils/erc20Helper";

// TODO: Rename env variable
const contractAddress = process.env.REACT_APP_ETHEREUM_CONTRACT_ADDRESS;

// TODO: Extract interface
export class AllbridgeEth {
    private contract: ethers.Contract;
    private provider: ethers.providers.Web3Provider;

    constructor(provider: ethers.providers.Web3Provider, signer: ethers.providers.JsonRpcSigner) {
        if (contractAddress == null) {
            throw new Error("Contract address should be defined in env variables!")
        }
        this.provider = provider;
        this.contract = new ethers.Contract(contractAddress, allbridgeAbi, signer);
    }

    async lockFunds(
        tokenAddress: string,
        recipient: string,
        destination: SupportedTokenSource,
        amount: number,
        onTransactionStatusChanged?: TransactionStatusChangedCallback): Promise<LockFundsResult> {
        try {
            const lockId = "0x01" + utils.hexlify(utils.randomBytes(16)).slice(4);
            let destinationBytes = utils.hexlify(utils.toUtf8Bytes(destination));
            if (destinationBytes.length < 10) {
                destinationBytes += "00";
            }
            const formattedRecepient = formatAddressTo32Hex(recipient, true);

            const transaction = await this.contract?.lock(
                lockId,
                utils.getAddress(tokenAddress),
                formattedRecepient,
                destinationBytes,
                utils.parseEther(amount.toString()));

            if (onTransactionStatusChanged != null) {
                onTransactionStatusChanged(transaction.hash);
            }

            await transaction.wait(10);

            const { hash } = transaction;

            const { data: {
                lockId: innerLockId,
                recipient: apiRecipient,
                amount: apiAmount,
                source,
                tokenSource,
                tokenSourceAddress,
                signature,
            } } = await axios.get(allBridgeSignEndpoint + hash);

            return {
                lockId: innerLockId as string,
                recipient: apiRecipient as string,
                amount: apiAmount as string,
                source: source as SupportedTokenSource,
                tokenSource: tokenSource as SupportedTokenSource,
                tokenSourceAddress: tokenSourceAddress as string,
                signature: signature as string,
            };
        }
        catch (err) {
            throw err;
        }
    }

    async unlockFunds(
        lockId: string,
        recipient: string,
        amount: string,
        lockSource: SupportedTokenSource,
        tokenSource: SupportedTokenSource,
        tokenSourceAddress: string,
        signature: string) {
        try {
            let lockSourceBytes = utils.hexlify(utils.toUtf8Bytes(lockSource));
            let tokenSourceBytes = utils.hexlify(utils.toUtf8Bytes(tokenSource));

            if (lockSourceBytes.length < 10) {
                lockSourceBytes += "00";
            }

            if (lockSourceBytes.length < 10) {
                lockSourceBytes += "00";
            }

            const { hash } = await this.contract?.unlock(
                lockId,
                utils.getAddress(recipient.slice(0, 42)),
                amount,
                lockSourceBytes,
                tokenSourceBytes,
                tokenSourceAddress,
                signature
            );

            return hash;
        }
        catch (err) {
            throw err;
        }
    }

    isAllowed(tokenAddress: string, userAddress: string) {
        return new Erc20Helper(this.provider, contractAddress).isAllowed(tokenAddress, userAddress);
    }

    approveAllowedAmountOrAll(tokenAddress: string, userAddress: string, amount?: number) {
        return new Erc20Helper(this.provider, contractAddress).approveAllowedAmountOrAll(tokenAddress, userAddress, amount);
    }

    getBalance(tokenAddress: string, userAddress: string) {
        return new Erc20Helper(this.provider, contractAddress).getBalance(tokenAddress, userAddress);
    }
}