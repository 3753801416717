import React from "react";
import { observer } from "mobx-react";

import { useAppState } from "../stores";
import SelectChainOption from "./SelectChainOption";

interface SelectChainToValueProps {
}

const SelectChainToValue: React.FC<SelectChainToValueProps> = observer(() => {
  const { bridge: { chainTo } } = useAppState();

  return (
    <>
      {chainTo &&
        <SelectChainOption chain={chainTo} />
      }
    </>
  );
});

export default SelectChainToValue;
