import React from "react";
import { useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { ProjectsButtons } from "@onxrp/ui-common";

import ChooseChainsStep from "../components/ChooseChainsStep";
import AddressStep from "../components/AddressStep";
import ConfirmationStep from "../components/ConfirmationStep";
import ReceiveStep from "../components/ReceiveStep";
import WaitWallets from "../components/WaitWallet";
import XummWallets from "../components/XummWallet";
import FinishTransfer from "../components/FinishTransfer";

interface BridgePageProps {}

const BridgePage: React.FC<BridgePageProps> = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const test = searchParams.get("test");

  return (
    <>
      <Container className="page">
        <Box display="flex" sx={{ mb: { xs: 3, md: 4 } }}>
          <ProjectsButtons activeLink="bridge" theme={theme} />
        </Box>
      </Container>
      <Container sx={{ maxWidth: { xs: "100%", md: "758px" }, mb: 6, mt: { xs: 1, sm: 3 } }} maxWidth={false}>
        {(!test || test === 'false') && (
          <Typography
            variant="h4"
            align="left"
            sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
          >
            The bridge has been temporarily disabled. Please keep an eye on our socials for updates.
          </Typography>
        )}

        {test === 'true' && (
          <>
            <FinishTransfer />
            <ChooseChainsStep />
            <AddressStep />
            <ConfirmationStep />
            {/* <ReceiveStep /> */}
            <WaitWallets />
            <XummWallets />
          </>
        )}
      </Container>
    </>
  );
};

export default BridgePage;
