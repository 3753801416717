import React from "react";
import { observer } from "mobx-react";
import {
  CircularProgress,
  Button,
  Typography,
  Dialog,
  DialogContent,
  Grid,
} from "@mui/material";
import { CloseButton } from "@onxrp/ui-common";

import { useAppState } from "../stores";

interface XummWalletProps { }

const XummWallet: React.FC<XummWalletProps> = observer(() => {
  const {
    xumm: { isSigningIn, qrCodeImg, mobileLink, resetSignIn },
  } = useAppState();


  return (
    <>
      <Dialog
        open={isSigningIn}
        keepMounted
        // @ts-ignore
        PaperComponent="div"
      >
        <DialogContent
          sx={{
            backgroundColor: "background.modalBody",
            borderRadius: "24px",
            padding: { md: "40px", xs: "24px" },
            textAlign: "center",
            minWidth: { xs: "320px", md: "500px" },
          }}
        >
          <Grid
            container
            justifyContent={"space-between"}
            alignItems="center"
            mb={3}
          >
            <Grid item>
              <Typography
                color="text.modal"
                fontSize="1.5rem"
                marginBottom={0}
                component="h2"
              >
                Connect to Wallet
              </Typography>
            </Grid>
            <Grid item>
              <CloseButton onClick={resetSignIn} />
            </Grid>
          </Grid>
          {mobileLink ? (
            <Grid
              container
              sx={{
                display: { md: "none", xs: "block" },
                justifyContent: "center",
              }}
            >
              <a
                href={mobileLink}
                rel="noreferrer"
                // @ts-ignore
                alt="Open in Xumm"
                target="_blank"
                style={{
                  color: "primary.main",
                  textDecoration: "none",
                  display: "block",
                  marginBottom: "1.2rem",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  className="gradient-button"
                  sx={{
                    p: 1.5,
                    borderRadius: "24px",
                  }}
                >
                  Open in XUMM
                </Button>
              </a>
            </Grid>
          ) : null}
          <Grid container justifyContent="center">
            {qrCodeImg ? (
              <img
                src={qrCodeImg}
                alt="Scan QR Code"
                style={{ maxWidth: "250px", width: "100%" }}
              />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default XummWallet;
